import React from 'react';
import { MDBCol } from 'mdbreact'

export default function admitModalContent(props) {

  let name = props.data.name;
  let quarantined = new Date(props.data.createdAt).toDateString();
  let symptoms = props.data.symptoms ? (JSON.parse(props.data.symptoms).length > 0) ? JSON.parse(props.data.symptoms).join() : 'No Symptoms' : "";
  let probability = JSON.parse(props.data.symptoms).length / 6 * 100;

  return (<MDBCol>
    <div>
      <label className='text-black-50'>Admit to:</label>
      <select style={{ float: "right" }} onChange={(value) => { props.onSelect(value.currentTarget.value) }}>
        <option disabled selected>Select Stage</option>
        <option value='PUI-AD'>Person Under Investigation (PUI)</option>
        <option value='CONFIRMED'>Confirmed Case</option>
        <option value='PUM-CL'>Cleared</option>
        <option value='DEAD'>Died</option>
      </select>
    </div>
    <br></br>
    <div>
      <label className='text-black-50'>Name:</label>
      <p style={{ float: "right" }}>{name}</p>
    </div><br></br>
    <div>
      <label className='text-black-50'>Date Quarantined:</label>
      <p style={{ float: "right" }}>{quarantined}</p>
    </div><br></br>
    <div>
      <p><label className='text-black-50'>Symptoms:</label>
        <span style={{ float: 'right' }}>{symptoms.replace(/,/g, ", ")}</span></p>
    </div>
    <div>
      <p><label className='text-black-50'>Probability:</label>
        <span style={{ float: 'right' }}>{probability.toFixed(2)} %</span></p>
    </div>
    <hr></hr>
    <label>Facility:</label><br></br>
    <input className='form-control' placeholder='Name of facility or hospital' onChange={(value) => { props.onChangeText("facility", value.currentTarget.value) }}></input>
    <br></br>
    <label>Remarks:</label><br></br>
    <textarea className='form-control' rows="3" placeholder='Enter the remarks of this person' onChange={(value) => { props.onChangeText("remarks", value.currentTarget.value) }}></textarea>
  </MDBCol>);
}