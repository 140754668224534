import React, { Component } from 'react';
import { MDBIcon, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader } from 'mdbreact'
import { connect } from 'react-redux';
import '../../../assets/styling/SchoolDirectory.css'
import { MDBContainer, MDBCol, MDBRow, MDBBtn } from 'mdbreact';
import { getSchoolDirectory, deleteSchoolDirectory, editSchoolDirectory, createSchoolDirectory } from '../../../reducer/Reducer'
import MaterialTable from 'material-table'
import { CSVLink } from "react-csv";
import CSVReader from 'react-csv-reader'
import { BallBeat } from 'react-pure-loaders';
import { persons } from '../../../constants/persons';
import axios from 'axios';
const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: header =>
    header
      .toLowerCase()
      .replace(/\W/g, '_')
}

export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      modal1: false,
      modal2: false,
      person: {}
    };
  }

  toggle = nr => () => {
    let modalNumber = 'modal' + nr
    this.setState({
      [modalNumber]: !this.state[modalNumber]
    });
  }

  handlePersons = async (data) => {
    await this.setState({ data })
  }

  handleErrorPersons = (error) => {
    alert(error)
  }

  handleChunk = (array, chunkSize) => {
    var R = [];
    for (var i = 0; i < array.length; i += chunkSize)
      R.push(array.slice(i, i + chunkSize));
    return R;
  }

  handleSubmit = async (e) => {
    let { data } = this.state;
    let chunks = this.handleChunk(data, 100)
    this.setState({ modal1: !this.state.modal1 });
    let token = await localStorage.getItem('auth-token')
    let area_code = await localStorage.getItem('area_code')
    try {
      chunks.map(async (chunk, index) => {
        await axios.post('https://ncov-monitoring-api.herokuapp.com/api/admission/bulk',
          { data: chunk },
          {
            headers: {
              "Authorization": token,
              "area_code": area_code
            }
          })
        if (index === chunks.length - 1) {
          this.setState({ modal1: !this.state.modal1 });
          alert('Successful Upload!')
          window.location.replace('/admin/dashboard')
        }
      })

    } catch (error) {
      this.setState({ modal1: !this.state.modal1 });
      alert('Failed To Upload!')
      console.log(error)
    }

  }



  handleCSVPreview() {
    return (
      <MDBContainer>
        <MDBContainer className='pt-0'>
          <MDBRow>
            <MDBCol md='12' className='text-right px-0'>
              <a href='/admin/monitoring/' className=''>
                <MDBIcon icon="times" />
              </a>
            </MDBCol>
            <MDBCol md='12' className='d-flex'>
              <h3 className='mx-auto font-weight-bold my-0'>Batch Upload</h3>
            </MDBCol>
            <MDBCol className='d-flex'>
              {/* <input className='my-auto' type="file" id="csv" name="csv" accept=".csv"/> */}
              <CSVReader
                cssClass="csv-reader-input"
                onFileLoaded={this.handlePersons}
                onError={this.handleErrorPersons}
                parserOptions={papaparseOptions}
                inputId="ObiWan"
                inputStyle={{ color: 'grey' }}
              />
              {/* <CSVReader 
                onDrop={this.onDrop}
                onError={this.onError}
                config={{header: false}}
              >
                <span>Drop CSV file here or click to upload.</span>
              </CSVReader> */}
            </MDBCol>
            <MDBCol className='d-flex pr-0'>
              <MDBBtn className='btn-primary my-auto ml-auto mr-0' disabled={(this.state.data.length > 0) ? false : true} onClick={() => {
                this.handleSubmit()
              }}>Submit</MDBBtn>
            </MDBCol>
            <MDBCol md='12' className='d-flex'>
              <CSVLink data={persons} filename={"PUM-Form.csv"}>Download Templete</CSVLink>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <MaterialTable
          title="Persons"
          columns={[
            { title: 'Name', field: 'name' },
            { title: 'Age', field: 'age' },
            { title: 'Gender', field: 'gender' },
            { title: 'Nationality', field: 'nationality' },
            { title: 'Occupation', field: 'occupation' },
            { title: 'Contact Number', field: 'contact_number' },
            { title: 'Office Address', field: 'office_address' },
            { title: 'Email', field: 'email' },
            { title: 'Area Code', field: 'area_code' },
            { title: 'House No.', field: 'house_num' },
            { title: 'Street', field: 'street' },
            { title: 'Barangay', field: 'brgy' },
            { title: 'Town/City', field: 'town_city' }
          ]}
          actions={[
            {
              icon: 'edit',
              tooltip: 'Edit PUM',
              onClick: (e, r) => {
                this.setState({ modal2: !this.state.modal2, person: r });
              }
            }
          ]}
          data={this.state.data}
          options={{
            actionsColumnIndex: -1,
            search: true,
            headerStyle: {
              backgroundColor: '#01579b',
              color: '#FFF'
            }
          }}
        />
        {/* Uploading modal */}
        <MDBModal isOpen={this.state.modal1} toggle={this.toggle(1)} size="sm" centered={true} disableBackdrop={true}>
          <MDBModalHeader className="align-self-center">Uploading</MDBModalHeader>
          <MDBModalBody className="align-self-center">
            <BallBeat
              style={{ alignSelf: 'center' }}
              color={'#123abc'}
              loading={true}
            />
          </MDBModalBody>
        </MDBModal>

        {/* Edit Modal */}
        <MDBModal isOpen={this.state.modal2} toggle={this.toggle(2)} size="lg">
          <MDBModalHeader toggle={this.toggle(2)}>Person Details</MDBModalHeader>
          <MDBModalBody>
            {JSON.stringify(this.state.person)}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.toggle(2)}>Close</MDBBtn>
            <MDBBtn color="primary">Save changes</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>

    )
  }

  render() {
    return (
      <MDBContainer fluid className='card p-0'>
        <MDBRow>
          <MDBCol>
            {this.handleCSVPreview()}
          </MDBCol>
        </MDBRow>
      </MDBContainer >
    );
  }
}

const mapStateToProps = state => {
  return {
    getSchoolDirectoryState: state.getSchoolDirectoryState,
    deleteSchoolDirectoryState: state.deleteSchoolDirectoryState,
    editSchoolDirectoryState: state.editSchoolDirectoryState,
    createSchoolDirectoryState: state.createSchoolDirectoryState
  };
};

const mapDispatchToProps = {
  getSchoolDirectory,
  deleteSchoolDirectory,
  editSchoolDirectory,
  createSchoolDirectory
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(index);