import React, { Component } from 'react';
import PinMarker from './marker';
import markerImage from './one.png'; 
const { compose, withProps, withState, withHandlers } = require("recompose");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} = require("react-google-maps");
const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");
const mapStyles = require('./mapStyle.json')
const google_map_api_key = 'AIzaSyCn732_cyp-q78jrdyhGYdT8NsoJWj3RZA';

const MapWithAMarkerClusterer = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${google_map_api_key}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div className="" style={{ width: `100%`, height: `100%` }} />,
  }),
  withHandlers({
    onMarkerClustererClick: () => (markerClusterer) => {
      const clickedMarkers = markerClusterer.getMarkers()
      console.log(`Current clicked markers length: ${clickedMarkers.length}`)
      console.log(clickedMarkers)
    },
  }),
  withScriptjs,
  withGoogleMap
)(props =>
  <GoogleMap
    defaultZoom={props.config.zoom}
    defaultCenter={{ lat: props.config.defaultLocation.lat, lng: props.config.defaultLocation.lng }}
    // defaultCenter={{ lat: 16.122017, lng: 120.225687 }}
    // options={map => ({ mapTypeId: map.MapTypeId.SATELLITE })}
    // options={map => ({ mapTypeId: 'satellite' })}
    // defaultOptions={map => ({ mapTypeId: map.MapTypeId.SATELLITE, styles: mapStyles })}
     defaultOptions={{ styles: mapStyles, maxZoom: 15, minZoom: 9 }}
  >
    <MarkerClusterer
      onClick={props.onMarkerClustererClick}
      averageCenter
      enableRetinaIcons
      gridSize={60}
    >
      {props.markers.map(marker => (
        <Marker
          // icon={{url: 'http://maps.google.com/mapfiles/ms/icons/hospitals.png'}}
          icon={{url: markerImage}}
          key={marker.markerId}
          position={{ lat: marker.latitude, lng: marker.longitude }}
        />
      ))}
    </MarkerClusterer>
  </GoogleMap>
);

export default MapWithAMarkerClusterer;