import React from 'react'
import SideNavigation from '../Sidenav/index'
import Content from './content'
import { Route, HashRouter } from 'react-router-dom'
import axios from 'axios';
import { towns } from '../../components/towns';
import LoadingScreen from '../../components/loadingScreen';

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      loading: true
    }
  }

  componentDidMount(){
    let token = localStorage.getItem('auth-token')
    if (token == null) {
      window.location.href = '/admin/login'
    }else{
      this.setState({loading: false})
    }
  }

  handleQuery = async (data) => {
    console.log("hahaha")
    const town = towns.find(town =>
      town.location === data
    )
    let token = localStorage.getItem('auth-token')
  }

  renderMainDiv(){
return (
      <HashRouter basename='/'>
        <SideNavigation data={this.handleQuery}>
          <Route exact path='/' render={(props) => <Content {...props} data={this.state.data} />} />
        </SideNavigation>
      </HashRouter>
    )
  }

  render() {
    const { loading } = this.state;
    if (loading) {
      return <LoadingScreen />
    } else {
      return this.renderMainDiv()
    } 
  }
}
