import React, { Component } from 'react';
import { connect } from 'react-redux';
import SimpleCrypt from './../components/simpleEncrypt';
import ERequest from './../components/request';
import AdmitModal from './../components/admitModalContent';
import Modal from './../components/modal';
import CustomTable from './../components/table';
import PLoader from './../components/processLoader';
import PersonsFullInfo from './../components/personInfoHandler';

export default class PUM extends Component{

  setState(states){
    this.props.setState(states)
  }

  table(){
    return CustomTable({
      id: "table5",
      title: 'Recovered Persons',
      columns:
        [
          { title: 'ID', field: 'p_id' },
          { title: 'Name', field: 'name' },
          { title: 'Town', field: 'town_city' },
          { title: 'Gender', field: 'gender' },
          { title: 'Age', field: 'age' },
          { title: 'Nationality', field: 'nationality' },
          { title: 'Date Cleared', field: 'lastupdate' },
        ],
      data: this.props.data.tableData.recovered || [],
      buttons: [
        {
          tooltip: 'View History', icon: 'fa-book-medical', color: 'btn-info', onClick: async (e) => {
            this.setState({ processing: true })
            let persondata = JSON.parse(e.currentTarget.dataset.metadata);
            let history = await ERequest('get', ' https://ncov-monitoring-api.herokuapp.com/api/history/' + persondata.p_id, null, {}, {});
            let hist = history.data;
            console.log(history);
            history.start = new Date(hist[hist.length - 1].updatedAt).toDateString();
            history.end = new Date(hist[0].updatedAt).toDateString();
            history.list = hist.map((data, x) => {
              let details = data.remarks.split(" * ");
              let l = details.join();
              if (details.length >= 2) {
                let stage = details[0].split("STAGE: ")[1];
                let facility = details[1].split("FACILITY: ")[1];
                let remarks = details[2].split("REMARKS: ")[1];
                l = <div><p>Stage: {stage}</p>
                  <p>Facility: {facility}</p>
                  <p>Remarks: {remarks}</p></div>;
              }
              return <li>
                <a>{data.action}</a>
                <a href="#" class="float-right">{new Date(data.updatedAt).toDateString()}</a>
                <p>
                  {l}
                </p>
              </li>
            });
            this.setState({ recoveredModal: !this.props.data.recoveredModal, pcldata: persondata, pclhistory: history, processing: false });
          }
        }
      ],
      onSearch: async (term, tableupdate) => {
        this.handleSearch(tableupdate, term, "RC");
      },
      onChangeBatch: (dir, tableupdate) => {
        this.handleOnChangeBatch(tableupdate, dir, "RC");
      }
    })
  }

  handleSearch = async (tableupdate, term, stage) => {
    let res = await ERequest("get", this.props.data.searchUrl, null, { term: term, page: this.props.data.page, size: this.props.data.size, stage: stage }, {});
    let data = await PersonsFullInfo(this.props.data, { stage: stage, page: this.props.data.page, size: this.props.data.size }, res, false) || [];
    console.log("Search Result: " + data);
    tableupdate.setAttribute("data-result", JSON.stringify(data));
    tableupdate.click();
  }

  handleOnChangeBatch = async (tableupdate, dir, stage) => {
    let params = { stage: stage, page: this.props.data.page, size: this.props.data.size }
    let page = eval(`${this.props.data.page} ${dir} 1`);
    if (page >= 0) { this.setState({ page: page }); }
    let res = await ERequest("get", this.props.data.url, null, params, {});
    let data = await PersonsFullInfo(this.props.data, params, res) || [];
    tableupdate.setAttribute("data-result", JSON.stringify(data));
    tableupdate.click();
  }

  render() {
    return (
      <div>
        {this.table()}
        {Modal({
          show: this.props.data.recoveredModal,
          size: "lg",
          title: "History",
          backdrop: true,
          content: <div class="container mt-5 mb-5">
            <div class="row">
              <div class="col-md-8 offset-md-2">
                <h4>{this.props.data.pcldata.name || ''}</h4>
                <ul class="timeline">
                  {this.props.data.pclhistory.list || ''}
                </ul>
              </div>
            </div>
          </div>,
          footer: [{
            class: 'btn-sm', text: 'CLOSE', onClick: () => { this.setState({ recoveredModal: false }) }
          }]
        })}
      </div>
    )
  }
}