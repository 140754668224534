import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../../assets/styling/SchoolDirectory.css'
import { MDBContainer, MDBCol, MDBRow, MDBBtn, MDBModal, MDBModalHeader, MDBNavLink } from 'mdbreact';
import avatar from '../../../assets/images/user.png'
import InputText from './InputText'
import { getSchoolDirectory, deleteSchoolDirectory, editSchoolDirectory, createSchoolDirectory } from '../../../reducer/Reducer'

export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      header: [
        'Avatar',
        'Firstname',
        'Lastname',
        'Position',
        'Email',
        'Number',
        'Department',
        'Office',
        'Edit'
      ],
      data: [],
      modal: false,
      toggleAdd: false,
      userInfo: [],
      fName: '',
      lName: '',
      position: '',
      phoneNumber: +'',
      department: '',
      office: '',
      editText: 'Edit',
      indexOnEdit: '',
      schoolDir: [],
      dirId: '',
      emailError: false,
      loading: true,
      apiError: false,

      activeItem: "1",
    };
  }

  renderHeader = () => {
    return this.state.header.map((title) => {
      return <td key={title} className='px-2 py-3 text-uppercase text-center inter-semibold font-size-18'>{title}</td>
    })
  }

  handleGetAllDirectory = async () => {
    await this.props.getSchoolDirectory()
    let response = this.props.getSchoolDirectoryState
    this.setState({ schoolDir: response })
  }

  renderBody = () => {
    let data = this.state.schoolDir
    try {
      if (data.length !== 0) {
        return data.map((item, index) => {
          return (
            <tr key={index} className='border-top w-100' >
              <td className='py-2 px-3 text-center'><img src={avatar} alt='' className='avatar-icon' /></td>
              <td className='py-2 px-3 text-center'>{item.firstName}</td>
              <td className='py-2 px-3 text-center'>{item.lastName}</td>
              <td className='py-2 px-3 text-center'>{item.position}</td>
              <td className='py-2 px-3 text-center'>{item.email}</td>
              <td className='py-2 px-3 text-center'>{item.phone}</td>
              <td className='py-2 px-3 text-center'>{item.department}</td>
              <td className='py-2 px-3 text-center'>{item.office}</td>
              <td className='py-2 px-3 text-center'><MDBBtn flat onClick={() => this.showInfo(item, index)} className='height-35 px-4 p-0 bg-dblue text-white br-5'>EDIT</MDBBtn></td>
            </tr>
          )
        })
      } else {
        this.setState({ loading: false })
      }

    }
    catch{
      return
    }

  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal, editText: 'Edit'
    })
  }

  showInfo = (item, index) => {
    this.setState({
      indexOnEdit: index,
      dirId: item.id,
      modal: true,
      fName: item.firstName,
      lName: item.lastName,
      email: item.email,
      position: item.position,
      department: item.department,
      phoneNumber: parseInt(item.phone),
      office: item.office
    });
  };

  toggleAdd = () => {
    this.setState({
      modalAdd: !this.state.modalAdd
    });
  };

  handleOnchange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  handleDelete = async () => {
    let id = this.state.dirId
    await this.props.deleteSchoolDirectory(id)
    // let response = this.props.deleteSchoolDirectoryState
    this.setState({ modal: false })
    window.location.reload()
  }

  handleEditInfo = () => {
    let { email, position, lName, fName, phoneNumber, office, department } = this.state
    return (
      <MDBModal isOpen={this.state.modal} toggle={() => this.toggle()}>
        <MDBModalHeader toggle={() => this.toggle()}>User Information</MDBModalHeader>
        <MDBContainer>
          <MDBCol className='text-center'>
            <img src={avatar} alt='' className='avatar-icon-100 mb-2' />
          </MDBCol>
          <MDBRow className='px-3'>
            <InputText
              label='Firstname'
              name='fName'
              type='text'
              change={this.handleOnchange}
              class='border mb-2 br-5 w-100 py-1 px-2'
              value={fName}
            />
            <InputText
              label='Lastname'
              name='lName'
              type='text'
              change={this.handleOnchange}
              class='border mb-2 br-5 w-100 py-1 px-2'
              value={lName}
            />
          </MDBRow>
          <InputText
            label='Email'
            name='email'
            type='text'
            pattern='^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
            change={this.handleOnchange}
            class='border mb-2 br-5 w-100 py-1 px-2'
            value={email}
          />
          <InputText
            label='Position'
            name='position'
            type='text'
            change={this.handleOnchange}
            class='border mb-2 br-5 w-100 py-1 px-2'
            value={position}
          />
          <InputText
            label='Phone Number'
            name='phoneNumber'
            type='number'
            change={this.handleOnchange}
            class='border mb-2 br-5 w-100 py-1 px-2'
            value={phoneNumber}
          />
          <InputText
            label='Department'
            name='department'
            type='text'
            change={this.handleOnchange}
            class='border mb-2 br-5 w-100 py-1 px-2'
            value={department}
          />
          <InputText
            label='Office'
            name='office'
            type='text'
            change={this.handleOnchange}
            class='border mb-2 br-5 w-100 py-1 px-2'
            value={office}
          />
          <MDBCol className='text-center'>
            <MDBBtn flat className='bg-green br-5 height-35 py-1 text-white width-120' onClick={() => { this.handleEdit() }}>SAVE</MDBBtn>
            <MDBBtn flat className='bg-red br-5 height-35 py-1 text-white width-120' onClick={() => { this.handleDelete() }}>DELETE</MDBBtn>
          </MDBCol>

        </MDBContainer>
      </MDBModal>
    )
  }

  handleEdit = async () => {
    let id = this.state.dirId
    let { email, position, lName, fName, phoneNumber, office, department } = this.state,
      data = {
        firstName: fName,
        lastName: lName,
        position: position,
        email: email,
        phone: phoneNumber,
        department: department,
        office: office
      }
    if (fName !== '' && lName !== '') {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\w{2,3})+$/.test(email)) {
        if (!isNaN(phoneNumber)) {
          this.setState({ modal: false, editText: 'Edit' })
          await this.props.editSchoolDirectory(data, id)
          window.location.reload()
        } else {
          alert('Please enter valid number')
        }
      } else {
        alert('Please enter valid email.')
      }
    } else {
      alert('Please enter name.')
    }
  }

  saveData = async (saveData) => {
    await this.props.createSchoolDirectory(saveData)
    this.setState({ modalAdd: false })
    window.location.reload()
  }



  toggleTab = tab => e => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab
      });
    }
  };

  render() {
    return (
      <MDBContainer fluid className='card p-0'>
        <MDBRow>
          <MDBCol>
            <form>
              <MDBContainer>
                <MDBRow className='mb-3'>
                  <MDBCol md='4'>
                  </MDBCol>
                  <MDBCol md='4' className='d-flex'>
                    <h2 className="font-weight-bold text-center my-auto mx-auto">Log</h2>
                  </MDBCol>
                  <MDBCol md='4' className='text-right' >
                    <MDBNavLink className='font-weight-bold' to='/logs'>X</MDBNavLink>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol className="grey-text">
                    <input type="text" placeholder='LastName FirstName MiddleName' className="form-control" />
                  </MDBCol>
                </MDBRow>
                <MDBRow className='my-2'>
                  <MDBCol className="grey-text">
                    <input type="number" placeholder='Age' className="form-control" />
                  </MDBCol>
                  <MDBCol className="grey-text">
                    <select className="browser-default custom-select">
                      <option>Choose your gender</option>
                      <option value="1">Male</option>
                      <option value="2">Female</option>
                    </select>
                  </MDBCol>
                  <MDBCol className="grey-text">
                    <input type="text" placeholder='Nationality' className="form-control" />
                  </MDBCol>
                  <MDBCol className="grey-text">
                    <input type="text" placeholder='Occupation' className="form-control" />
                  </MDBCol>
                  <MDBCol className="grey-text">
                    <input type="number" placeholder='Contact Number' className="form-control" />
                  </MDBCol>
                </MDBRow>
                <MDBRow className='my-2'>
                  <MDBCol className="grey-text">
                    <input type="text" placeholder='Home Address' className="form-control" />
                  </MDBCol>
                  <MDBCol className="grey-text">
                    <input type="text" placeholder='Street Number' className="form-control" />
                  </MDBCol>
                  <MDBCol className="grey-text">
                    <input type="text" placeholder='Barangay' className="form-control" />
                  </MDBCol>
                </MDBRow>
                <MDBRow className='my-2'>
                  <MDBCol className="grey-text my-2">
                    <input type="text" placeholder='Office Address' className="form-control" />
                  </MDBCol>
                  <MDBCol className="grey-text my-2">
                    <input type="email" placeholder='Email' className="form-control" />
                  </MDBCol>
                  <MDBCol md='12' className="grey-text">
                    <input type="text" placeholder='Date & purpose of visit' className="form-control" />
                  </MDBCol>
                </MDBRow>
                <MDBRow className='my-2'>
                  <MDBCol className="grey-text">
                    <input type="text" placeholder='Type of vehicle' className="form-control" />
                  </MDBCol>
                  <MDBCol className="grey-text">
                    <input type="text" placeholder='Plate number of vehicle' className="form-control" />
                  </MDBCol>
                </MDBRow>
                <MDBRow className='my-2'>
                  <MDBCol className="grey-text">
                    <input type="number" placeholder='Seat number if by Bus' className="form-control" />
                  </MDBCol>
                  <MDBCol className="grey-text">
                    <input type="text" placeholder='Place of destination of Baguio City' className="form-control" />
                  </MDBCol>
                </MDBRow>
                <MDBRow className='my-2'>
                  <MDBCol className="grey-text">
                    <input type="text" placeholder='Foreign Countries or Other Region' className="form-control" />
                  </MDBCol>
                </MDBRow>
                <MDBRow className='my-4'>
                  <MDBCol md='12'>
                    <label>Symptom's Checklist</label>
                  </MDBCol>
                  <MDBCol className='px-lg-5' >
                    <MDBRow className='w-75'>
                      <MDBCol>
                        <div>
                          <input type="checkbox" aria-label="Checkbox for following text input" />
                          <span className='ml-2'>Fever</span>
                        </div>
                      </MDBCol>
                      <MDBCol>
                        <div>
                          <input type="checkbox" aria-label="Checkbox for following text input" />
                          <span className='ml-2'>Colds</span>
                        </div>
                      </MDBCol>
                      <MDBCol>
                        <div>
                          <input type="checkbox" aria-label="Checkbox for following text input" />
                          <span className='ml-2'>Diarhea</span>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className='w-50 my-2'>
                      <MDBCol>
                        <div>
                          <input type="checkbox" aria-label="Checkbox for following text input" />
                          <span className='ml-2'>Fever</span>
                        </div>
                      </MDBCol>
                      <MDBCol>
                        <div>
                          <input type="checkbox" aria-label="Checkbox for following text input" />
                          <span className='ml-2'>Colds</span>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className='w-50 my-2'>
                      <MDBCol>
                        <div>
                          <input type="checkbox" aria-label="Checkbox for following text input" />
                          <span className='ml-2'>Fever</span>
                        </div>
                      </MDBCol>
                      <MDBCol>
                        <div>
                          <input type="checkbox" aria-label="Checkbox for following text input" />
                          <span className='ml-2'>Colds</span>
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBContainer >
    );
  }
}

const mapStateToProps = state => {
  return {
    getSchoolDirectoryState: state.getSchoolDirectoryState,
    deleteSchoolDirectoryState: state.deleteSchoolDirectoryState,
    editSchoolDirectoryState: state.editSchoolDirectoryState,
    createSchoolDirectoryState: state.createSchoolDirectoryState
  };
};

const mapDispatchToProps = {
  getSchoolDirectory,
  deleteSchoolDirectory,
  editSchoolDirectory,
  createSchoolDirectory
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(index);