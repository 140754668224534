import React from 'react';
import { MDBCol } from 'mdbreact'

export default function InputText(props) {

  return (
    <MDBCol>
      <p className='mb-1'>{props.label}</p>
      <input
        name={props.name}
        type={props.type}
        patern={props.pattern}
        onChange={props.change}
        className={`${props.class} border mb-2 br-5 w-100 py-1 px-2`}
        value={props.value}
      />
    </MDBCol>
  );
}