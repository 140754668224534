import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React from 'react';
import Routes from './views/routes/index';
import './App.css';

function App() {
  return (
    <Routes />
  );
}

export default App;
