export const Coords = [
  {
    "location": "Agno",
    "code": 2408,
    "coords": "16.116086, 119.802683"
  },
  {
    "location": "Aguilar",
    "code": 2415,
    "coords": "15.889886, 120.237853"
  },
  {
    "location": "Alaminos City",
    "code": 2404,
    "coords": "16.155314, 119.980769"
  },
  {
    "location": "Alcala",
    "code": 2425,
    "coords": "15.846794, 120.521822"
  },
  {
    "location": "Anda",
    "code": 2405,
    "coords": "16.289614, 119.9491"
  },
  {
    "location": "Asingan",
    "code": 2439,
    "coords": "16.0044, 120.6545"
  },
  {
    "location": "Balungao",
    "code": 2442,
    "coords": "15.9, 120.7"
  },
  {
    "location": "Bani",
    "code": 2407,
    "coords": "16.18694, 119.85917"
  },
  {
    "location": "Basista",
    "code": 2422,
    "coords": "15.8524, 120.3976"
  },
  {
    "location": "Bautista",
    "code": 2424,
    "coords": "15.78, 120.5"
  },
  {
    "location": "Bayambang",
    "code": 2423,
    "coords": "15.809450, 120.457140"
  },
  {
    "location": "Binalonan",
    "code": 2436,
    "coords": "16.047440, 120.592331"
  },
  {
    "location": "Binmaley",
    "code": 2417,
    "coords": "16.028410, 120.269180"
  },
  {
    "location": "Bolinao",
    "code": 2406,
    "coords": "16.388840, 119.894363"
  },
  {
    "location": "Bugallon",
    "code": 2416,
    "coords": "15.954380, 120.217520"
  },
  {
    "location": "Burgos",
    "code": 2410,
    "coords": "16.04647, 119.85678"
  },
  {
    "location": "Calasiao",
    "code": 2418,
    "coords": "16.02, 120.37"
  },
  {
    "location": "Dagupan City",
    "code": 2400,
    "coords": "16.03, 120.33"
  },
  {
    "location": "Dasol",
    "code": 2411,
    "coords": "15.98964, 119.88053"
  },
  {
    "location": "Infanta",
    "code": 2412,
    "coords": "15.82083, 119.90833"
  },
  {
    "location": "Labrador",
    "code": 2402,
    "coords": "16.03389, 120.13917"
  },
  {
    "location": "Laoac",
    "code": 2437,
    "coords": "16.03333, 120.55"
  },
  {
    "location": "Lingayen",
    "code": 2401,
    "coords": "16.01667, 120.23333"
  },
  {
    "location": "Mabini",
    "code": 2409,
    "coords": "16.06972, 119.94"
  },
  {
    "location": "Malasiqui",
    "code": 2421,
    "coords": "15.919987, 120.414546"
  },
  {
    "location": "Manaoag",
    "code": 2430,
    "coords": "16.044504, 120.487348"
  },
  {
    "location": "Mangaldan",
    "code": 2432,
    "coords": "16.067060, 120.404628"
  },
  {
    "location": "Mangatarem",
    "code": 2413,
    "coords": "15.791149, 120.294157"
  },
  {
    "location": "Mapandan",
    "code": 2429,
    "coords": "16.0184, 120.4646"
  },
  {
    "location": "Natividad",
    "code": 2446,
    "coords": "16.042429, 120.796653"
  },
  {
    "location": "Pozorrubio",
    "code": 2435,
    "coords": "16.112499, 120.549192"
  },
  {
    "location": "Rosales",
    "code": 2441,
    "coords": "15.894321, 120.630320"
  },
  {
    "location": "San Carlos City",
    "code": 2420,
    "coords": "15.926170, 120.348269"
  },
  {
    "location": "San Fabian",
    "code": 2433,
    "coords": "16.119934, 120.403939"
  },
  {
    "location": "San Jacinto",
    "code": 2431,
    "coords": "16.072924, 120.437861"
  },
  {
    "location": "San Manuel",
    "code": 2438,
    "coords": "16.065086, 120.670143"
  },
  {
    "location": "San Nicolas",
    "code": 2447,
    "coords": "16.070456, 120.760748"
  },
  {
    "location": "San Quintin",
    "code": 2444,
    "coords": "15.983693, 120.812326"
  },
  {
    "location": "Santa Barbara",
    "code": 2419,
    "coords": "15.996953, 120.405226"
  },
  {
    "location": "Santa Maria",
    "code": 2440,
    "coords": "15.980238, 120.699069"
  },
  {
    "location": "Santo Tomas",
    "code": 2426,
    "coords": "15.878171, 120.583506"
  },
  {
    "location": "Sison",
    "code": 2434,
    "coords": "16.172107, 120.509132"
  },
  {
    "location": "Sual",
    "code": 2403,
    "coords": "16.065066, 120.096411"
  },
  {
    "location": "Tayug",
    "code": 2445,
    "coords": "16.028322, 120.748091"
  },
  {
    "location": "Umingan",
    "code": 2443,
    "coords": "15.929024, 120.842799"
  },
  {
    "location": "Urbiztondo",
    "code": 2414,
    "coords": "15.824301, 120.330952"
  },
  {
    "location": "Urdaneta",
    "code": 2428,
    "coords": "15.975799, 120.570725"
  },
  {
    "location": "Villasis",
    "code": 2427,
    "coords": "15.914522, 120.580169"
  }
]