import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
// import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { MDBContainer, MDBModal, MDBModalHeader, MDBCol, MDBBtn, MDBRow, MDBNavLink } from 'mdbreact';
import './../../assets/styling/Sidenav.css'
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { getSchool } from '../../reducer/Reducer';

class SideNav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      smallDevice: true,
      toggled: false,
      isGridActive: true,
      isPlusActive: false,
      modal: false,
      redirectToDashboard: false,
      schoolName: '',
      search: '',
      userName: '',
      userLevel: ''
    }
  }

  componentDidMount() {
    this.checkSize()
    let uname = localStorage.getItem("user-level") == "SA" ? "Provincial" : "Municipal"
    let uLvl = localStorage.getItem("area_code")
    let username = localStorage.getItem("user_name")
    this.setState({
      userName: uname == "SA" ? "Administrator" : username,
      userLevel: uLvl + " | " + uname
    })
    window.addEventListener('resize', this.checkSize);
  }

  checkSize = () => {
    this.setState({ smallDevice: window.innerWidth < 768, toggled: false })
  }

  HandleActiveLink = (path) => { // function to specify the current active page
    return window.location.hash.split('/')[1] === path ? 'active-nav' : 'notActive-nav';
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  logOut = () => {
    localStorage.removeItem('schoolId')
    localStorage.removeItem('token')
    localStorage.removeItem('auth-token')
    localStorage.removeItem('user-level')
    localStorage.removeItem('area_code')
    window.location.href = '/admin/login'
  }

  handleLogOutModal = () => {
    return (
      <MDBModal isOpen={this.state.modal} toggle={() => this.toggle}>
        <MDBModalHeader toggle={() => this.toggle()}>Log Out</MDBModalHeader>
        <MDBContainer>
          <MDBCol className="text-center">
            <p>Are you sure you want to log out?</p>
          </MDBCol>
          <MDBCol className='text-center'>
            <MDBBtn flat className='height-30 btn btn-primary text-white p-0 width-120 height-35 br-5' onClick={() => this.logOut()}>YES</MDBBtn>
            <MDBBtn flat className='height-30 btn btn-default text-white p-0 width-120 height-35 br-5' onClick={() => this.toggle()}>NO</MDBBtn>
          </MDBCol>
        </MDBContainer>
      </MDBModal>
    )
  }

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  handleSearchQuery = () => {
    this.props.data(this.state.search)
  }

  handleRedirect(e) {
    e.preventDefault();
    let link = e.currentTarget.getAttribute("to");
    window.location.pathname = link;
  }

  render() {
    let { redirectToDashboard, search } = this.state;
    if (redirectToDashboard) return <Redirect to='/' />;
    return (

      <div className='main-container' id='nav-container' >
        <div className={'sidebar-bg ' + (this.state.toggled ? 'show' : 'hide')} onClick={() => this.setState({ toggled: false })}></div>
        <div className={this.state.smallDevice ? 'sidebar text-white' + (this.state.toggled ? 'mobile' : 'hidden') : 'sidebar text-black'} style={{ overflow: 'scroll' }}>
          <div className="heading text-white">
            <div className="profile">
              <MDBCol md='12' className='d-flex'>
                <img className="mx-auto my-4" src="https://pangasinan.gov.ph/wp-content/uploads/2011/03/provincial-seal-1580.jpg" width="100" height="100"></img>
              </MDBCol>
              <MDBCol>
                <div className="details">
                  <h4>{this.state.userName || ''}</h4>
                  <p>{this.state.userLevel || ''}</p>
                </div>
              </MDBCol>
            </div>
          </div>
          <MDBContainer style={{ height: 'inherit' }}>
            <MDBRow className="flex-column align-items-start">
              <ul class="sidebar-links">
                <li className="">
                  <a href="javascript:void(0)" to="/admin/dashboard" onClick={this.handleRedirect}>
                    <i class="material-icons">pie_chart</i>Dashboard
                  </a>
                </li>
                <li className="">
                  <a href="javascript:void(0)" to="/admin/monitoring/" onClick={this.handleRedirect}><i class="material-icons">web</i>Monitoring</a>
                </li>
                <li className="">
                  <a href="javascript:void(0)" to="/admin/tagging/" onClick={this.handleRedirect}><i class="material-icons">low_priority</i>Tagging</a>
                </li>
                <li className="">
                  <a href="javascript:void(0)" to="/admin/logs/" onClick={this.handleRedirect}><i class="material-icons">history</i>Logs</a>
                </li>
                <li className="">
                  <a onClick={() => this.toggle()}><i class="material-icons">power_settings_new</i>Logout</a>
                </li>
              </ul>
            </MDBRow>
            <MDBRow className='flex-center align-items-end'>
              <div class="footer">
                <p><Moment format='MMMM Do YYYY, h:mm:ss a' interval={1000} /></p>
              </div>
            </MDBRow>
          </MDBContainer>
        </div>
        <div className='content background-image-projectx'>
          <MDBContainer fluid className='mt-2'>
            {this.props.children}
          </MDBContainer>
        </div>
        {this.handleLogOutModal()}
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    getSchoolState: state.getSchoolState,
  };
};

const mapDispatchToProps = {
  getSchool

};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideNav);