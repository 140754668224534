// import { func } from "prop-types";

export const VRERIFY_SCHOOL = 'VRERIFY_SCHOOL';
export const VRERIFY_SCHOOL_SUCCESS = 'VRERIFY_SCHOOL_SUCCESS';
export const VRERIFY_SCHOOL_FAIL = 'VRERIFY_SCHOOL_FAIL';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const GET_ALL_NOTIF = 'GET_ALL_NOTIF';
export const GET_ALL_NOTIF_SUCCESS = 'GET_ALL_NOTIF_SUCCESS';
export const GET_ALL_NOTIF_FAIL = 'GET_ALL_NOTIF_FAIL';

export const GET_SPECIFIC_NOTIF = 'GET_SPECIFIC_NOTIF';
export const GET_SPECIFIC_NOTIF_SUCCESS = 'GET_SPECIFIC_NOTIF_SUCCESS';
export const GET_SPECIFIC_NOTIF_FAIL = 'GET_SPECIFIC_NOTIF_FAIL';

export const CREATE_NOTIF = 'CREATE_NOTIF';
export const CREATE_NOTIF_SUCCESS = 'CREATE_NOTIF_SUCCESS';
export const CREATE_NOTIF_FAIL = 'CREATE_NOTIF_FAIL';

export const DELETE_NOTIF = 'DELETE_NOTIF';
export const DELETE_NOTIF_SUCCESS = 'DELETE_NOTIF_SUCCESS';
export const DELETE_NOTIF_FAIL = 'DELETE_NOTIF_FAIL';

export const EDIT_NOTIF = 'EDIT_NOTIF';
export const EDIT_NOTIF_SUCCESS = 'EDIT_NOTIF_SUCCESS';
export const EDIT_NOTIF_FAIL = 'EDIT_NOTIF_FAIL';

export const GET_SCHOOL_DIRECTORY = 'GET_SCHOOL_DIRECTORY';
export const GET_SCHOOL_DIRECTORY_SUCCESS = 'GET_SCHOOL_DIRECTORY_SUCCESS';
export const GET_SCHOOL_DIRECTORY_FAIL = 'GET_SCHOOL_DIRECTORY_FAIL';

export const DELETE_DIRECTORY = 'DELETE_DIRECTORY';
export const DELETE_DIRECTORY_SUCCESS = 'DELETE_DIRECTORY_SUCCESS';
export const DELETE_DIRECTORY_FAIL = 'DELETE_DIRECTORY_FAIL';

export const EDIT_SCHOOL_DIRECTORY = 'EDIT_DIRECTORY';
export const EDIT_SCHOOL_DIRECTORY_SUCCESS = 'EDIT_DIRECTORY_SUCCESS';
export const EDIT_SCHOOL_DIRECTORY_FAIL = 'EDIT_DIRECTORY_FAIL';

export const CREATE_SCHOOL_DIRECTORY = 'CREATE_SCHOOL_DIRECTORY';
export const CREATE_SCHOOL_DIRECTORY_SUCCESS = 'CREATE_SCHOOL_DIRECTORY_SUCCESS';
export const CREATE_SCHOOL_DIRECTORY_FAIL = 'CREATE_SCHOOL_DIRECTORY_FAIL';

export const CREATE_SCHOOL_INFO = 'CREATE_SCHOOL_INFO';
export const CREATE_SCHOOL_INFO_SUCCESS = 'CREATE_SCHOOL_INFO_SUCCESS';
export const CREATE_SCHOOL_INFO_FAIL = 'CREATE_SCHOOL_INFO_FAIL';

export const GET_SPECIFIC_SCHOOL_INFO = 'GET_SPECIFIC_SCHOOL_INFO';
export const GET_SPECIFIC_SCHOOL_INFO_SUCCESS = 'GET_SPECIFIC_SCHOOL_INFO_SUCCESS';
export const GET_SPECIFIC_SCHOOL_INFO_FAIL = 'GET_SPECIFIC_SCHOOL_INFO_FAIL';

export const EDIT_SCHOOL_INFO = 'EDIT_SCHOOL_INFO';
export const EDIT_SCHOOL_INFO_SUCCESS = 'EDIT_SCHOOL_INFO_SUCCESS';
export const EDIT_SCHOOL_INFO_FAIL = 'EDIT_SCHOOL_INFO_FAIL';

export const GET_CALENDAR = 'GET_CALENDAR';
export const GET_CALENDAR_SUCCESS = 'GET_CALENDAR_SUCCESS';
export const GET_CALENDAR_FAIL = 'GET_CALENDAR_FAIL';

export const CREATE_CALENDAR = 'CREATE_CALENDAR';
export const CREATE_CALENDAR_SUCCESS = 'CREATE_CALENDAR_SUCCESS';
export const CREATE_CALENDAR_FAIL = 'CREATE_CALENDAR_FAIL';

export const EDIT_CALENDAR = 'EDIT_CALENDAR';
export const EDIT_CALENDAR_SUCCESS = 'EDIT_CALENDAR_SUCCESS';
export const EDIT_CALENDAR_FAIL = 'EDIT_CALENDAR_FAIL';

export const GET_SCHOOL = 'GET_SCHOOL';
export const GET_SCHOOL_SUCCESS = 'GET_SCHOOL_SUCCESS';
export const GET_SCHOOL_FAIL = 'GET_SCHOOL_FAIL';

export const DELETE_CALENDAR = 'DELETE_CALENDAR';
export const DELETE_CALENDAR_SUCCESS = 'DELETE_CALENDAR_SUCCESS';
export const DELETE_CALENDAR_FAIL = 'DELETE_CALENDAR_FAIL';

export default function reducer(
  state = {
    verifySchoolState: {},
    loginState: {},
    getAllNotifState: {},
    createNotifState: {},
    getSpecificNotifState: {},
    deleteNotifState: {},
    editNotifState: {},
    getSchoolDirectoryState: {},
    deleteSchoolDirectoryState: {},
    editSchoolDirectoryState: {},
    createSchoolDirectoryState: {},
    createSchoolInfoState: {},
    getSpecificSchoolInfoState: {},
    editSchoolInfoState: {},
    getCalendarState: {},
    createCalendarState: {},
    getSchoolState: {},
    editCalendarState: {},
    deleteCalendarState: {},
  },
  action
) {
  switch (action.type) {
    case VRERIFY_SCHOOL:
      return { ...state, loading: true };
    case VRERIFY_SCHOOL_SUCCESS:
      return { ...state, loading: false, verifySchoolState: action.payload.data };
    case VRERIFY_SCHOOL_FAIL:
      return { ...state, loading: false, verifySchoolState: action };

    case LOGIN:
      return { ...state, loading: true };
    case LOGIN_SUCCESS:
      return { ...state, loading: false, loginState: action.payload.data };
    case LOGIN_FAIL:
      return { ...state, loading: false, loginState: action };

    case GET_ALL_NOTIF:
      return { ...state, loading: true };
    case GET_ALL_NOTIF_SUCCESS:
      return { ...state, loading: false, getAllNotifState: action.payload.data };
    case GET_ALL_NOTIF_FAIL:
      return { ...state, loading: false, getAllNotifState: action };

    case CREATE_NOTIF:
      return { ...state, loading: true };
    case CREATE_NOTIF_SUCCESS:
      return { ...state, loading: false, createNotifState: action.payload.data };
    case CREATE_NOTIF_FAIL:
      return { ...state, loading: false, createNotifState: action };

    case GET_SPECIFIC_NOTIF:
      return { ...state, loading: true };
    case GET_SPECIFIC_NOTIF_SUCCESS:
      return { ...state, loading: false, getSpecificNotifState: action.payload.data };
    case GET_SPECIFIC_NOTIF_FAIL:
      return { ...state, loading: false, getSpecificNotifState: action };

    case DELETE_NOTIF:
      return { ...state, loading: true };
    case DELETE_NOTIF_SUCCESS:
      return { ...state, loading: false, deleteNotifState: action.payload.data };
    case DELETE_NOTIF_FAIL:
      return { ...state, loading: false, deleteNotifState: action };

    case EDIT_NOTIF:
      return { ...state, loading: true };
    case EDIT_NOTIF_SUCCESS:
      return { ...state, loading: false, editNotifState: action.payload.data };
    case EDIT_NOTIF_FAIL:
      return { ...state, loading: false, editNotifState: action };

    case GET_SCHOOL_DIRECTORY:
      return { ...state, loading: true };
    case GET_SCHOOL_DIRECTORY_SUCCESS:
      return { ...state, loading: false, getSchoolDirectoryState: action.payload.data };
    case GET_SCHOOL_DIRECTORY_FAIL:
      return { ...state, loading: false, getSchoolDirectoryState: action };

    case DELETE_DIRECTORY:
      return { ...state, loading: true };
    case DELETE_DIRECTORY_SUCCESS:
      return { ...state, loading: false, deleteSchoolDirectoryState: action.payload.data };
    case DELETE_DIRECTORY_FAIL:
      return { ...state, loading: false, deleteSchoolDirectoryState: action };

    case EDIT_SCHOOL_DIRECTORY:
      return { ...state, loading: true };
    case EDIT_SCHOOL_DIRECTORY_SUCCESS:
      return { ...state, loading: false, editSchoolDirectoryState: action.payload.data };
    case EDIT_SCHOOL_DIRECTORY_FAIL:
      return { ...state, loading: false, editSchoolDirectoryState: action };

    case CREATE_SCHOOL_DIRECTORY:
      return { ...state, loading: true };
    case CREATE_SCHOOL_DIRECTORY_SUCCESS:
      return { ...state, loading: false, createSchoolDirectoryState: action.payload.data };
    case CREATE_SCHOOL_DIRECTORY_FAIL:
      return { ...state, loading: false, createSchoolDirectoryState: action };

    case CREATE_SCHOOL_INFO:
      return { ...state, loading: true };
    case CREATE_SCHOOL_INFO_SUCCESS:
      return { ...state, loading: false, createSchoolInfoState: action.payload.data };
    case CREATE_SCHOOL_INFO_FAIL:
      return { ...state, loading: false, createSchoolInfoState: action };

    case GET_SPECIFIC_SCHOOL_INFO:
      return { ...state, loading: true };
    case GET_SPECIFIC_SCHOOL_INFO_SUCCESS:
      return { ...state, loading: false, getSpecificSchoolInfoState: action.payload.data };
    case GET_SPECIFIC_SCHOOL_INFO_FAIL:
      return { ...state, loading: false, getSpecificSchoolInfoState: action };

    case EDIT_SCHOOL_INFO:
      return { ...state, loading: true };
    case EDIT_SCHOOL_INFO_SUCCESS:
      return { ...state, loading: false, editSchoolInfoState: action.payload.data };
    case EDIT_SCHOOL_INFO_FAIL:
      return { ...state, loading: false, editSchoolInfoState: action };

    case GET_CALENDAR:
      return { ...state, loading: true };
    case GET_CALENDAR_SUCCESS:
      return { ...state, loading: false, getCalendarState: action.payload.data };
    case GET_CALENDAR_FAIL:
      return { ...state, loading: false, getCalendarState: action };

    case CREATE_CALENDAR:
      return { ...state, loading: true };
    case CREATE_CALENDAR_SUCCESS:
      return { ...state, loading: false, createCalendarState: action.payload.data };
    case CREATE_CALENDAR_FAIL:
      return { ...state, loading: false, createCalendarState: action };

    case EDIT_CALENDAR:
      return { ...state, loading: true };
    case EDIT_CALENDAR_SUCCESS:
      return { ...state, loading: false, editCalendarState: action.payload.data };
    case EDIT_CALENDAR_FAIL:
      return { ...state, loading: false, editCalendarState: action };

    case GET_SCHOOL:
      return { ...state, loading: true };
    case GET_SCHOOL_SUCCESS:
      return { ...state, loading: false, getSchoolState: action.payload.data };
    case GET_SCHOOL_FAIL:
      return { ...state, loading: false, getSchoolState: action };

    case DELETE_CALENDAR:
      return { ...state, loading: true };
    case DELETE_CALENDAR_SUCCESS:
      return { ...state, loading: false, deleteCalendarState: action.payload.data };
    case DELETE_CALENDAR_FAIL:
      return { ...state, loading: false, deleteCalendarState: action };

    default:
      return state;
  }
}

export function verifySchool(data) {
  return {
    type: VRERIFY_SCHOOL,
    payload: {
      request: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        url: `school/verify`,
        credentials: 'include',
        data: data,
      },
    },
  };
}

export function login(data) {
  return {
    type: LOGIN,
    payload: {
      request: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        url: `user/auth`,
        credentials: 'include',
        data: data,
      },
    },
  };
}

export function getAllNotif() {
  return {
    type: GET_ALL_NOTIF,
    payload: {
      request: {
        method: 'Get',
        headers: {
          Authorization: `${localStorage.getItem('token')}`,
        },
        url: `notification/school/${localStorage.getItem('schoolId')}`,
      },
    },
  };
}

export function getSpecificNotif(id) {
  return {
    type: GET_SPECIFIC_NOTIF,
    payload: {
      request: {
        method: 'GET',
        headers: {
          Authorization: `${localStorage.getItem('token')}`,
        },
        url: `notification/${id}`,
      },
    },
  };
}

export function createNotif(data) {
  return {
    type: CREATE_NOTIF,
    payload: {
      request: {
        method: 'POST',
        headers: {
          Authorization: `${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        url: `notification`,
        credentials: 'include',
        data: data,
      },
    },
  };
}

export function deleteNotif(id) {
  return {
    type: DELETE_NOTIF,
    payload: {
      request: {
        method: 'DELETE',
        headers: {
          Authorization: `${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        url: `notification/${id}`,
      },
    },
  };
}

export function editNotif(data, id) {
  return {
    type: EDIT_NOTIF,
    payload: {
      request: {
        method: 'PUT',
        headers: {
          Authorization: `${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        url: `notification/${id}`,
        credentials: 'include',
        data: data,
      },
    },
  };
}

export function getSchoolDirectory() {
  return {
    type: GET_SCHOOL_DIRECTORY,
    payload: {
      request: {
        method: 'Get',
        headers: {
          Authorization: `${localStorage.getItem('token')}`,
        },
        url: `directory/school/${localStorage.getItem('schoolId')}`,
      },
    },
  };
}

export function deleteSchoolDirectory(id) {
  return {
    type: DELETE_DIRECTORY,
    payload: {
      request: {
        method: 'DELETE',
        headers: {
          Authorization: `${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        url: `directory/${id}`,
      },
    },
  };
}

export function editSchoolDirectory(data, id) {
  return {
    type: EDIT_SCHOOL_DIRECTORY,
    payload: {
      request: {
        method: 'PUT',
        headers: {
          Authorization: `${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        url: `directory/${id}`,
        credentials: 'include',
        data: data,
      },
    },
  };
}

export function createSchoolDirectory(data) {
  return {
    type: CREATE_NOTIF,
    payload: {
      request: {
        method: 'POST',
        headers: {
          Authorization: `${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        url: `directory`,
        credentials: 'include',
        data: data,
      },
    },
  };
}

export function createSchoolInfo(data) {
  return {
    type: CREATE_SCHOOL_INFO,
    payload: {
      request: {
        method: 'POST',
        headers: {
          Authorization: `${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        url: `information`,
        credentials: 'include',
        data: data,
      },
    },
  };
}

export function getSpecificSchoolInfo() {
  let id = localStorage.getItem('schoolId');
  return {
    type: GET_SPECIFIC_SCHOOL_INFO,
    payload: {
      request: {
        method: 'GET',
        headers: {
          Authorization: `${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        url: `information/${id}`,
      },
    },
  };
}

export function editSchoolInfo(data, id) {
  return {
    type: EDIT_SCHOOL_DIRECTORY,
    payload: {
      request: {
        method: 'PUT',
        headers: {
          Authorization: `${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        url: `information/${id}`,
        credentials: 'include',
        data: data,
      },
    },
  };
}

export function getCalendar() {
  let id = localStorage.getItem('schoolId');
  return {
    type: GET_CALENDAR,
    payload: {
      request: {
        method: 'Get',
        headers: {
          Authorization: `${localStorage.getItem('token')}`,
        },
        url: `calendar/school/${id}`,
      },
    },
  };
}

export function createCalendar(data) {
  return {
    type: CREATE_CALENDAR,
    payload: {
      request: {
        method: 'POST',
        headers: {
          Authorization: `${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        url: `calendar`,
        credentials: 'include',
        data: data,
      },
    },
  };
}

export function editCalendar(data, id) {
  return {
    type: EDIT_CALENDAR,
    payload: {
      request: {
        method: 'PUT',
        headers: {
          Authorization: `${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        url: `calendar/${id}`,
        credentials: 'include',
        data: data,
      },
    },
  };
}

export function getSchool(id) {
  return {
    type: GET_SCHOOL,
    payload: {
      request: {
        method: 'Get',
        headers: {
          Authorization: `${localStorage.getItem('token')}`,
        },
        url: `school/${id}`,
      },
    },
  };
}

export function deleteCalendar(id) {
  return {
    type: DELETE_CALENDAR,
    payload: {
      request: {
        method: 'DELETE',
        headers: {
          Authorization: `${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        url: `calendar/${id}`,
      },
    },
  };
}
