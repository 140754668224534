import React from 'react';
import { BallBeat } from 'react-pure-loaders';

const LoadingScreen = () => {
  return(
    <div className="loading-screen">
        <BallBeat
          style={{alignSelf: 'center'}}
          color={'#123abc'}
          loading={true}
        />
    </div>
  )
}

export default LoadingScreen;