import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBContainer, MDBCol, MDBRow, MDBCard, MDBCardBody, MDBIcon } from 'mdbreact';
import { Doughnut } from 'react-chartjs-2';
import { MDBListGroup, MDBListGroupItem, MDBBadge } from 'mdbreact';
// import { Chart, Series, CommonSeriesSettings, Legend, Export, Tooltip, Border } from 'devextreme-react/chart';
import Moment from 'react-moment';
import axios from 'axios';
import '../../index.css'
import ERequest from '../admission/components/request';
import LoadingScreen from '../../components/loadingScreen';

export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataDoughnut: {},
      colors: [],
      updates: null,
      today: [],
      monitoringData: null,
      loading: true,
      admitted: 0,
      recovered: 0,
      confirmed: 0,
      death: 0,
      recoveryRate: 0,
      deathRate: 0,
      totalCases: 0,
      pumqrTotal: 0,
      pumclTotal: 0,
      puiadTotal: 0,
      puidsTotal: 0
    };
  }

  componentDidMount = async () => {
    // this.getMonitoringData()

    if(!localStorage.getItem("auth-token")){
      window.location.href = "/admin/login";
    }
    let areacode = localStorage.getItem('user-level') && localStorage.getItem("user-level") == 'LA' ? localStorage.getItem("area_code") : '';

    try {
      //UPDATES
      const today = await ERequest('get', 'https://ncov-monitoring-api.herokuapp.com/api/monitoring/today/' + areacode, null, {}, {})
      const all = await ERequest('get', 'https://ncov-monitoring-api.herokuapp.com/api/monitoring/data/' + areacode, null, {}, {})
      console.log(today, all)
      let dailyupdates = today.data.data;
      let allupdates = all.data;
      let colors = this.getRandomColors(allupdates.total_case);
      let d = {
        labels: allupdates.graph.labels,
          datasets: [{
            data: allupdates.graph.data,
            backgroundColor: colors,
            hoverBackgroundColor: colors,
          },
        ],
      };
      this.setState({ updates: allupdates, today: dailyupdates, dataDoughnut: d, colors: colors, loading: false });

    } catch (error) {
      console.log(error);
    }
  };

  getRandomColors = (len) => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    let res = [];
    for (var x = 0; x < len; x++) {
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      res.push(color);
      color = "#";
    }
    return res;
  }

  renderMunicipalities = (areas) => {
    if (!areas) return null;
    if(this.state.updates.isFiltered){
    return (areas.map((data, index) => {
      return <tr>
        <td className="text-right"><span className="badge" style={{background: `${this.state.colors.length > 0 ? this.state.colors[index] : '#fff'}` }}>&nbsp;&nbsp;&nbsp;</span> </td>
        <td>{data.brgy}</td>
        <td>{data.cases}</td>
      </tr>
    }));
    }else{
    return (areas.map((data, index) => {
      return <tr>
        <td className="text-right"><span className="badge" style={{background: `${this.state.colors.length > 0 ? this.state.colors[index] : '#fff'}` }}>&nbsp;&nbsp;&nbsp;</span> </td>
        <td>{data.location}</td>
        <td>{data.cases}</td>
      </tr>
    }));
    }
  }

  renderFacilities = (facilities) => {
    if (!facilities) return null;
    return (facilities.map((data, index) => {
      return <tr>
        <td>{data.facility}</td>
        <td>{data.cases}</td>
      </tr>
    }));
  }

  renderMainDiv() {
    const { monitoringData } = this.state;
    return (

      <MDBContainer fluid className='my-5'>

        <div className="row mb-3">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                Daily Updates
              </div>
              <div className="card-body">
                <div className="row">

                  <div className="col-lg-2">
                    <div className="card">
                      <div className="card-body">
                        <h4 class="card-title">{this.state.today.today_under_quarantine || 0}</h4>
                        <p class="card-text">Monitoring</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-2">
                    <div className="card">
                      <div className="card-body">
                        <h4 class="card-title">{this.state.today.today_admitted || 0}</h4>
                        <p class="card-text">Admitted</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-2">
                    <div className="card">
                      <div className="card-body">
                        <h4 class="card-title">{this.state.today.today_confirmed || 0}</h4>
                        <p class="card-text">New Case</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-2">
                    <div className="card">
                      <div className="card-body">
                        <h4 class="card-title">{this.state.today.today_cleared || 0}</h4>
                        <p class="card-text">Cleared</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-2">
                    <div className="card">
                      <div className="card-body">
                        <h4 class="card-title">{this.state.today.today_recovered || 0}</h4>
                        <p class="card-text">Recovered</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-2">
                    <div className="card">
                      <div className="card-body">
                        <h4 class="card-title">{this.state.today.today_dead || 0}</h4>
                        <p class="card-text">Died</p>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
              <div className="card-footer text-right">
                <Moment fromNow format="LLLL" />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div class="col-lg-3">
            <div className="card">
              <div className="card-header">
                Admitted
              </div>
              <div className="card-body card-large">
                <h4 className="text-right text-info text-huge">{
                  this.state.updates.total_admission || 0
                }</h4>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div className="card">
              <div className="card-header">
                Recovered
              </div>
              <div className="card-body card-large">
                <h4 className="text-right text-success text-huge">
                  {
                    this.state.updates.total_recovered || 0
                  }
                </h4>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div className="card">
              <div className="card-header">
                Confirmed
              </div>
              <div className="card-body card-large">
                <h4 className="text-right text-red text-huge">{
                  this.state.updates.total_confirmed || 0
                }</h4>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div className="card">
              <div className="card-header">
                Death
              </div>
              <div className="card-body card-large">
                <h4 className="text-right text-dark text-huge">{
                  this.state.updates.total_dead || 0
                }</h4>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <div className="row">
          <div className="col-lg-4">
            <div className="card">
              <div className="card-body">
                <h4 className="text-left text-dark">{
                  this.state.updates.recovery_rate || 0
                }%</h4>
              </div>
              <div className="card-footer">
                Recovery Rate
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card">
              <div className="card-body">
                <h4 className="text-left text-dark">{
                  this.state.updates.death_rate || 0
                }%</h4>
              </div>
              <div className="card-footer">
                Death Rate
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card">
              <div className="card-body">
                <h4 className="text-left text-dark">{
                  this.state.updates.total_case || 0
                }</h4>
              </div>
              <div className="card-footer">
                Total Cases
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <hr></hr>
        <br></br>
        <div className="row">
          <div className="col-lg-12 text-center">
              <h4>Data Representation of Cases {this.state.updates.isFiltered ? 'From ' + this.state.updates.caseFrom : 'By City/Town'}</h4>
            <br></br>
            <Doughnut data={this.state.dataDoughnut} options={{ responsive: true, legend: false }} />
            <br></br>
          </div>
        </div>

        <br></br><br></br>
        <div className="row">
          <div className="col-lg-6">
            <div className="card">
              <div className="card-header">
                Confirmed Cases
              </div>
              <div className="card-body scrollable-table">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th></th>
                      <th>{this.state.updates.isFiltered ? 'Barangay' : 'Town/City'}</th>
                      <th>Cases</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderMunicipalities(this.state.updates.areas)}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="card">
              <div className="card-header">
                Active Facilities
              </div>
              <div className="card-body scrollable-table">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Facility/Hospital</th>
                      <th>Cases</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderFacilities(this.state.updates.facilities)}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
        <br></br>
        <hr></hr>
        <br></br>
        <div className="row">
          <div className="col-lg-6 text-center">
            <div className="card">
              <div className="card-header">
                <h5>PUM</h5>
                <p>(Persons Under Monitoring)</p>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6">
                    <h4>{
                      this.state.updates.total_under_monitoring || 0}</h4>
                  </div>
                  <div className="col-lg-6">
                    Under 14 days of quarantine
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <h4>{
                      this.state.updates.total_pum_cl || 0}</h4>
                  </div>
                  <div className="col-lg-6">
                    Completed Quarantine
                  </div>
                </div>
              </div>
              <div className="card-footer text-right">
                <h2>{
                  this.state.updates.total_under_monitoring || 0}</h2> Total PUM recorded
              </div>
            </div>
          </div>

          <div className="col-lg-6 text-center">
            <div className="card">
              <div className="card-header">
                <h5>PUI</h5>
                <p>(Persons Under Investigation)</p>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6">
                    <h4>{
                      this.state.updates.total_admission || 0}</h4>
                  </div>
                  <div className="col-lg-6">
                    Admitted
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <h4>{
                      this.state.updates.total_pui_dc || 0}</h4>
                  </div>
                  <div className="col-lg-6">
                    Discharged
                  </div>
                </div>
              </div>
              <div className="card-footer text-right">
                <h2>{
                  this.state.updates.total_admission || 0}</h2> Total PUI recorded
              </div>
            </div>
          </div>

        </div>
        <br></br>
        <br></br>
        <div className="row mt-5">
          <div className="col-lg-12">
            <div className="card-body text-right">
              <p><i className="fa fa-clock"></i> Latest updates as of {this.state.updates.lastUpdate || " today"}</p>
            </div>
            <div className="card-footer text-left">
              <p><small><a target="_blank" href="/">Show Public Page</a></small></p>
              <p><small>For more national updates, go to <a target="_blank" href="https://ncovtracker.doh.gov.ph/">DOH Website</a></small></p>
            </div>
          </div>
        </div>
        <br></br>
      </MDBContainer >
    )
  }

  render() {
    const { loading } = this.state;
    if (loading) {
      return <LoadingScreen />
    } else {
      return this.renderMainDiv()
    }
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(index);
