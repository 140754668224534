import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import { createStore, applyMiddleware } from "redux";
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from "react-redux";
import axios from "axios";
import multiClientMiddleware from "redux-axios-middleware";
import reducer from "./reducer/Reducer";

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

const client = axios.create({
  // baseURL: 'https://operahtek-api-dev.herokuapp.com/',
  // baseURL: 'http://localhost:3000',
  baseURL: 'https://7xpnr5752d.execute-api.us-east-1.amazonaws.com/dev/api/', //dev api
  // baseURL: 'https://ofjzz86yv6.execute-api.us-east-1.amazonaws.com/prod/api/',  //prod api
  responseType: "json",
});

const store = createStore(
  reducer,
  applyMiddleware(multiClientMiddleware(client))
);

ReactDOM.render(
  <Provider store={store}>
    <Router><App /></Router>
  </Provider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
