import React from 'react'
import SideNavigation from '../Sidenav/index'
// import Notification from '../notification/index'
// import Calendar from '../calendar/index'
// import SchoolList from '../schoolInformation/index'
import Admission from '../admission/index'
import ViewLogs from '../viewLogs/index'
import AddPUM from '../addPUM/index'
import EditPUM from '../admission/components/editPUM'
import LogDetails from '../admission/components/logDetails'
import UploadCSV from '../admission/components/uploadCsv'
import { Route, HashRouter } from 'react-router-dom'
import axios from 'axios';
import { towns } from '../../components/towns';

export default class Monitoring extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: ''
    }
  }

  handleQuery = async (data) => {
    console.log("hahaha")
    const town = towns.find(town =>
      town.location === data
    )
    let token = localStorage.getItem('auth-token')
  }

  render() {
    return (
      <HashRouter basename='/'>
        <SideNavigation data={this.handleQuery}>
          <Route exact path='/' render={(props) => <Admission {...props} data={this.state.data} />} />
          <Route path='/logs' component={ViewLogs} />
          <Route path='/add_pum' component={AddPUM} />
          <Route path='/edit_pum' component={EditPUM} />
          <Route path='/log_details' component={LogDetails} />
          <Route path='/upload_csv' component={UploadCSV} />
        </SideNavigation>
      </HashRouter>
    )
  }
}
