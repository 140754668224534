import React, { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import axios from 'axios';
import './styles.css';
import LoadingScreen from '../../components/loadingScreen';
import { Coords } from '../../components/coords';
import MapWithAMarkerClusterer from '../landing/map';
import Modal from '../admission/components/modal';

export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coordinates: [],
      updates: null,
      loading: true,
      markers: {},
      mapConfig: {},
      toFilter: '',
      targetPlace: '',
    };
  }

  componentDidMount = async () => {
    try {

      let coordinates = await this.getPangasinanCoordinates();
      this.setState({coordinates: coordinates});

      fetch('https://ncov-monitoring-api.herokuapp.com/api/monitoring/data/' + this.getTargetFilterPlace())
        .then(response => response.json())
        .then(res => {
          console.log(res)
          res.map.zoom = res.map.zoom - 2.44
          let mapData = this.getBrgyCoordinates(res.map.data)
          this.setState({ markers: mapData, mapConfig: res.map, updates: res, loading: false })
        })
    } catch (error) {
      console.log(error);
    }
  }

  getTargetFilterPlace(){
    let coords = this.state.coordinates;
    let href = window.location.href;
    let areacode = href.includes("?area=") ? href.split("?area=")[1] : ''; 
    console.log(window.location.href, areacode)
    if(areacode == "all"){
      window.location.href = "/v2/"
    }
    if(areacode != ''){
      let town = coords.find(function(loc){return loc.code == areacode})
      this.setState({targetPlace: town.town})
    }
    return areacode
  }

  getPangasinanCoordinates = async () => {
    let result;
    await fetch('https://raw.githubusercontent.com/bpaltezo08/Pangasinan-Coords/master/data%20(8).json')
      .then(response => response.json())
      .then(res => {
        result = res;
    })
    return result || []
  }

  getBrgyCoordinates(data){
    let coords = this.state.coordinates;
    for(var x=0;x<data.length;x++){
      data[x].markerId = x
      let brgyname = data[x].brgy
      let town = coords.find(function(loc){return loc.code == data[x].area_code})
      let brgy = town.brgy.find(function(loc){return loc.brgy == brgyname.toUpperCase()})
      if(brgy != undefined){
        data[x].latitude = brgy.coords.lat
        data[x].longitude = brgy.coords.lng
      }else{
        data[x].latitude = town.coords.lat
        data[x].longitude = town.coords.lng
      }
    }
  return data;
  }

  renderMunicipalities = (areas) => {
    if (!areas) return null;
    if(areas.length > 0){
      return (areas.map((data, index) => {
        if(data.location){
        return <tr>
          <td>{data.location}</td>
          <td>{data.cases}</td>
        </tr>
        }else if(data.brgy){
                  return <tr>
          <td>{data.brgy}</td>
          <td>{data.cases}</td>
        </tr>
        }
      }));
    }else{
      return <tr>
        <td colSpan="2" className='text-center'>No Records</td>
      </tr>
    }
    
  }

  handleFilterOptions = () => {
    return Coords.map((area, index) => {
      return <option value={area.code}>{area.location}</option>
    });
  }

  handleOnFilterChange = (val) => {
    window.location.href = "?area=" + val
  }

  renderMainDiv(){
    return (
    <div className="v2-container">
<div className="container-fluid">
    <div className="row">
      <div className="col-lg-12">

        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h2>{this.state.updates.caseFrom == "All" ? "Entire Pangasinan" : this.state.updates.caseFrom}</h2>
                    <span><small>Updated as of <Moment date={this.state.updates.lastUpdate} format="LLLL" /></small></span>
                    <select className="float-right" onChange={(e) => {this.handleOnFilterChange(e.currentTarget.value)}}>
                      <option disabled>Select Municipality</option>
                      {this.state.updates.isFiltered ? <option disabled selected>{this.state.updates.caseFrom}</option>: null}
                      <option value="all">Entire Pangasinan</option>
                      {this.handleFilterOptions()}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row text-center mt-2">
          <div className="col-lg-12">

            <div className="container-fluid">
              <div className="row">

                <div className="col-lg-3 px-1">
                  <div className="card border-stripe stripe-blue">
                    <div className="card-body">
                      <h5 className="text-left">ADMITTED
                        <i className="text-right material-icons">group_add</i>
                      </h5>
                      <h4 className="text-left">{this.state.updates.total_admission || 0}</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 px-1">
                  <div className="card border-stripe stripe-green">
                    <div className="card-body">
                      <h5 className="text-left">RECOVERED
                        <i className="text-right material-icons">verified_user</i>
                      </h5>
                      <h4 className="text-left">{this.state.updates.total_recovered || 0}</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 px-1">
                  <div className="card border-stripe stripe-red">
                    <div className="card-body">
                      <h5 className="text-left">CONFIRMED
                        <i className="text-right material-icons">how_to_reg</i>
                      </h5>
                      <h4 className="text-left">{this.state.updates.total_confirmed || 0}</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 px-1">
                  <div className="card border-stripe stripe-dark">
                    <div className="card-body">
                      <h5 className="text-left">DECEASED
                        <i className="text-right material-icons">highlight_off</i>
                      </h5>
                      <h4 className="text-left">{this.state.updates.total_dead || 0}</h4>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className="container-fluid">
              <div className="row mt-2">

                <div className="col-lg-2 px-1">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="text-left text-small">UNDER<br></br>QUARANTINE</h5>
                      <h4 className="text-right">{this.state.updates.total_under_monitoring || 0}</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 px-1">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="text-left text-small">COMPLETED <br></br>QUARANTINE</h5>
                      <h4 className="text-right">{this.state.updates.total_pum_cl || 0}</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 px-1">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="text-left text-small">DISCHARGED<br></br>PATIENTS</h5>
                      <h4 className="text-right">{this.state.updates.total_pui_dc || 0}</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 px-1">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="text-left text-small">RECOVERY<br></br>RATE</h5>
                      <h4 className="text-right">{this.state.updates.recovery_rate || 0}%</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 px-1">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="text-left text-small">DEATH<br></br>RATE</h5>
                      <h4 className="text-right">{this.state.updates.death_rate || 0}%</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 px-1">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="text-left text-small">TOTAL<br></br>CASES</h5>
                      <h4 className="text-right text-danger">{this.state.updates.total_case || 0}</h4>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className="container-fluid">

              <div className="row mt-2">

                <div className="col-lg-6 px-1">

                  <div className="card h-300">
                    <div className="card-header">
                      <h5><b>Confirmed Cases</b></h5>
                      <span><small>{this.state.updates.caseFrom == "All" ? "Entire Pangasinan" : this.state.updates.caseFrom}</small></span>
                    </div>
                    <div className="card-body scroll">
                      <table className="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th>{this.state.updates.isFiltered ? 'Barangays' : 'Municipalities'}</th>
                            <th>Cases</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.renderMunicipalities(this.state.updates.areas)}
                        </tbody>
                      </table>
                    </div>


                  </div>

                </div>

                <div className="col-lg-6 px-1">
                  <div className="card h-300">
                    <div id="map">
                      {
                        this.state.markers.length > 0 ? <MapWithAMarkerClusterer markers={this.state.markers} config={this.state.mapConfig} /> : null
                      }
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>

        <div className="row text-center mt-3 mb-2">
          <div className="col-lg-12">
            For more national updates, visit <a href="https://ncovtracker.doh.gov.ph"
              target="_blank">ncovtracker.doh.gov.ph</a>
            <p><small>Created by <a href="https://rnrph.com/" target="_blank">RNRPH</a></small></p>
          </div>
        </div>

      </div>
    </div>
  </div>
    </div>)
  }

  render() {
    const { loading } = this.state;
    if (loading) {
      return <LoadingScreen />
    } else {
      return this.renderMainDiv()
    }
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(index);
