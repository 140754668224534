import React, { Component } from 'react';
import { connect } from 'react-redux';
import SimpleCrypt from './../components/simpleEncrypt';
import ERequest from './../components/request';
import AdmitModal from './../components/admitModalContent';
import Modal from './../components/modal';
import CustomTable from './../components/table';
import PLoader from './../components/processLoader';
import PersonsFullInfo from './../components/personInfoHandler';

export default class PUI extends Component{

  setState(states){
    this.props.setState(states)
  }

  table(){
    return CustomTable({
      id: "table2",
      title: 'Admitted Persons Under Investigation',
      columns:
        [
          { title: 'Person', field: 'nameInfo' },
          { title: 'Town', field: 'town_city' },
          { title: 'Facility', field: 'facility' },
          { title: 'Nationality', field: 'nationality' },
          { title: 'Symptoms', field: 'symptomsDOM' },
          { title: 'Tags', field: 'totalTags' },
        ],
      load: true,
      data: this.props.data.tableData.puiAD || [],
      buttons: [
        {
          tooltip: 'Tracking & History', icon: 'fa-street-view', color: 'btn-default', onClick: (e) => {
            let persondata = JSON.parse(e.currentTarget.dataset.metadata);
            this.setState({ puiModal1: !this.props.data.puiModal1, puidata: persondata });
          }
        },
        {
          tooltip: 'View Tags', icon: 'fa-sitemap', color: 'btn-warning', onClick: async (e) => {
            this.setState({ processing: true })
            let persondata = JSON.parse(e.currentTarget.dataset.metadata);
            let postags = await ERequest('post', ' https://ncov-monitoring-api.herokuapp.com/api/tracking/tags', null, {}, persondata);
            let data = postags.data.data;

            console.log("PUI DATA: ", data);
            for(var x=0;x<data.length;x++){
              data[x].relation = ''
              if(data[x].address != 'false') data[x].relation += 'Same Address'
              if(data[x].colleague != 'false') data[x].relation += ', Colleague'
              if(data[x].vehicle != 'false') data[x].relation += ', Same Vehicle'
            }

            this.setState({ puiModal2: !this.props.data.puiModal2, puidata: persondata, puitags: data, processing: false });
          }
        }, {
          tooltip: 'Update Status', icon: 'fa-edit', color: 'btn-danger', onClick: (e) => {
            let persondata = JSON.parse(e.currentTarget.dataset.metadata);
            this.setState({ puiModal3: !this.props.data.puiModal3, itoAdmit: persondata });
          }
        }
      ],
      onSearch: async (term, tableupdate) => {
        this.handleSearch(tableupdate, term, "PUI-AD");
      },
      onChangeBatch: (dir, tableupdate) => {
        this.handleOnChangeBatch(tableupdate, dir, "PUI-AD");
      }
    })
  }

  handleSearch = async (tableupdate, term, stage) => {
    let res = await ERequest("get", this.props.data.searchUrl, null, { term: term, page: this.props.data.page, size: this.props.data.size, stage: stage }, {});
    let data = await PersonsFullInfo(this.props.data, { stage: stage, page: this.props.data.page, size: this.props.data.size }, res, false) || [];
    console.log("Search Result: " + data);
    tableupdate.setAttribute("data-result", JSON.stringify(data));
    tableupdate.click();
  }

  handleOnChangeBatch = async (tableupdate, dir, stage) => {
    let params = { stage: stage, page: this.props.data.page, size: this.props.data.size }
    let page = eval(`${this.props.data.page} ${dir} 1`);
    if (page >= 0) { this.setState({ page: page }); }
    let res = await ERequest("get", this.props.data.url, null, params, {});
    let data = await PersonsFullInfo(this.props.data, params, res) || [];
    tableupdate.setAttribute("data-result", JSON.stringify(data));
    tableupdate.click();
  }

  render() {
    return (
      <div>
        {this.table()}
        {Modal({
          show: this.props.data.puiModal1,
          size: "lg",
          title: "Tracking History | " + (this.props.data.puidata.p_id || ''),
          backdrop: true,
          content: <div><br></br><div className="row text-center">
            <div className="col-lg-4">
              <label className="text-black-50">Arrival Date</label>
              <p>{this.props.data.puidata.date_visit || ''}</p>
            </div>
            <div className="col-lg-4">
              <label className="text-black-50">Date Admitted</label>
              <p>{new Date(this.props.data.puidata.updatedAt).toLocaleString() || ''}</p>
            </div>
            <div className="col-lg-4">
              <label className="text-black-50">Recent Status</label>
              <p>{this.props.data.puidata.status || ''}</p>
            </div>
          </div>
            <hr></hr>
            <div className="row text-center">
              <div className="col-lg-4">
                <label className="text-black-50">Vehicle Used Upon Arrival</label>
                <p>{this.props.data.puidata.v_type || ''}</p>
              </div>
              <div className="col-lg-4">
                <label className="text-black-50">Seat Number (If by BUS)</label>
                <p>{this.props.data.puidata.v_bus_seat_num || 'N/A'}</p>
              </div>
              <div className="col-lg-4">
                <label className="text-black-50">Vehicle Plate Number</label>
                <p>{this.props.data.puidata.v_plate_num || ''} <br></br>{this.props.data.puidata.v_plate_num == "" ? "" : <a href="#"><span className="badge badge-danger"> Detect Passengers</span></a>}</p>
              </div>
              <br></br>
            </div>
            <hr></hr>
            <div className="row text-center">
              <div className="col-lg-4">
                <label className="text-black-50">Places Visited Within {this.props.data.puidata.town_city || "Pangasinan"}</label>
                <p>{this.props.data.puidata.hometown_visits || ''}</p>
              </div>
              <div className="col-lg-4">
                <label className="text-black-50">Places Visited Outside Province</label>
                <p>{this.props.data.puidata.foreign_visits || ''}</p>
              </div>
              <div className="col-lg-4">
                <label className="text-black-50">Purpose Of Visit In {this.props.data.puidata.town_city || "Pangasinan"}</label>
                <p>{this.props.data.puidata.visit_purpose || ''}</p>
              </div>
              <br></br>
            </div>
          </div>,
          footer: [{
            class: 'btn-sm', text: 'CLOSE', onClick: () => { this.setState({ puiModal1: false }) }
          }]
        })}

        {Modal({
          show: this.props.data.puiModal2,
          size: "xl",
          title: "Records Of Contacts",
          backdrop: true,
          content: <div>
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <p>Confirmed Tags of <b>{this.props.data.puidata.name}</b></p>
                    </div>
                    <div className="card-body">
                      <h2 className="text-right">{this.props.data.puidata.tags}</h2>
                    </div>
                    <div className="card-footer text-right">
                      <a href="/admin/monitoring/add_pum" className="btn btn-sm btn-default text-right">Tag New Person</a>
                      {this.props.data.puidata.tags > 0 ? <a href="/admin/tagging" className="btn btn-sm btn-info text-right">View Tags</a> : null}                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
          <hr />
          {CustomTable({
            id: "tabletags",
            title: 'Showing Possible Contacts of ' + (this.props.data.puidata.p_id || ''),
            columns:
              [
                { title: 'ID', field: 'p_id' },
                { title: 'Name', field: 'name' },
                { title: 'Relation', field: 'relation' },
                { title: 'Remark', field: 'remark' }
              ],
            data: this.props.data.puitags,
            buttons: [
              {
                tooltip: 'Tag this person', icon: 'fa-user-tag', color: 'btn-danger', onClick: async (e) => {
                  this.setState({ processing: true });
                  let persondata = JSON.parse(e.currentTarget.dataset.metadata);
                  let res = await ERequest('post', 'https://ncov-monitoring-api.herokuapp.com/api/tracking/', null, {}, {
                    p_id: this.props.data.puidata.p_id,
                    tags: persondata.p_id
                  });
                  console.log("result", res);
                  await this.setState({ processing: false });
                  window.location.reload();
                }
              }
            ],
            onSearch: async (term, tableupdate) => {
              let tags = await ERequest('post', ' https://ncov-monitoring-api.herokuapp.com/api/search/tags', null, { name: term }, {});
              console.log("Search Result: " + tags.data.data);
              tableupdate.setAttribute("data-result", JSON.stringify(tags.data.data));
              tableupdate.click();
            },
            onChangeBatch: (dir, tableupdate) => {
              return [];
            }
          })}
          </div>
          </div>,
          footer: [
            {
              class: 'btn-sm', text: 'CLOSE', onClick: () => { this.setState({ puiModal2: false }) }
            }]
        })}

        {Modal({
          show: this.props.data.puiModal3,
          size: "md",
          title: "Update Status",
          backdrop: true,
          content: <div>
            <label className="text-black-50">Mark as</label>
            <select className="form-control" onChange={(e) => { this.setState({ iadmitStage: e.currentTarget.value }) }}>
              <option disabled selected value="">Select Status</option>
              <option value="CONFIRMED">Positive</option>
              <option value="PUI-DC">Negative</option>
              <option value="DEAD">Died</option>
            </select>
            <br></br>
            <label className="text-black-50">Current Facility</label>
            <input type="text" className="form-control" placeholder="Facility or Hospital" placeholder={this.props.data.itoAdmit.facility} onChange={(e) => { this.setState({ ifacility: e.currentTarget.value }) }} ></input>
            <br></br>
            <label className="text-black-50">Remarks</label>
            <textarea rows="3" className="form-control" placeholder="" onChange={(e) => { this.setState({ iremarks: e.currentTarget.value }) }}></textarea>
          </div>,
          footer: [{
            class: '', text: 'CANCEL', onClick: () => { this.setState({ puiModal3: false }) }
          }, {
            class: '', text: 'ADMIT', onClick: async () => {
              let data = this.props.data.itoAdmit;
              let facility = this.props.data.ifacility == '' ? data.facility : this.props.data.ifacility
              let stage = this.props.data.iadmitStage == '' ? data.status : this.props.data.iadmitStage
              if (this.props.data.iadmitStage && this.props.data.iremarks && facility) {
                this.setState({ processing: true, puiModal3: false });
                let admission = await ERequest('get', this.props.data.admitUrl + data.p_id, null, {}, {});
                let admissionID = admission.data.data.id;
                let res = await ERequest('put', this.props.data.admitUrl + admissionID, true, {}, {
                  p_id: data.p_id,
                  stage: stage,
                  remarks: this.props.data.iremarks,
                  facility: facility
                });
                console.log(data.p_id, admission, res, facility);
                this.setState({ processed: true });
                window.location.reload();
              } else {
                alert("Please complete required fields!");
              }
            }
          }]
        })}
      </div>
    )
  }
}