import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../assets/styling/SchoolDirectory.css'
import { MDBContainer, MDBCol, MDBRow, MDBBtn, MDBModal, MDBModalHeader, MDBIcon, MDBInput, MDBModalBody, MDBModalFooter, MDBFooter, MDBNavLink } from 'mdbreact';
import avatar from './../../assets/images/user.png'
import InputText from '../admission/components/InputText'
import { getSchoolDirectory, deleteSchoolDirectory, editSchoolDirectory, createSchoolDirectory } from '../../reducer/Reducer';
import axios from 'axios';
import { towns } from '../../components/towns';
import { nationalities } from '../../components/nationalities';
import { BallSpinFadeLoader } from 'react-pure-loaders';

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addPersonModal: false,
      success: false,
      error: false,
      modal: false,
      toggleAdd: false,
      loading: false,
      activeItem: "1",
      name: '',
      age: '',
      gender: '',
      nationality: '',
      occupation: '',
      contact_number: '',
      office_address: '',
      email: '',
      area_code: null,
      house_num: '',
      street: '',
      brgy: '',
      town_city: '',
      hw_id: '',
      date_visit: '',
      visit_purpose: '',
      v_type: '',
      v_plate_num: '',
      v_bus_seat_num: '',
      v_by_bus: false,
      hometown_visits: '',
      foreign_visits: '',
      status: '',
      household_members: '',
      household_details: [],
      symptoms: [],
      townIndex:0
    };
  }

  toggle = () => {
    const { modal } = this.state
    this.setState({
      modal: !modal,
    })
  }

  toggleSuccess = () => {
    const { success } = this.state
    this.setState({
      success: !success,
    })
  }

  toggleError = () => {
    const { error } = this.state
    this.setState({
      error: !error,
    })
  }


  toggleAdd = () => {
    this.setState({
      modalAdd: !this.state.modalAdd
    });
  };

  toggleTab = tab => e => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab
      });
    }
  };

  handleChange = ({ target }) => {
    for(let i = 0 ; i < towns.length; i++){
      if(target.value == towns[i].location){
        this.setState({ townIndex: i })
      }
    }
    this.setState({ [target.name]: target.value });
  };

  handleHouseholdMembersChange = (event) => {
    const { target } = event
    const { value } = target
    let members = []
    for (let i = 0; i < value; i++) {
      members.push({ age: '', gender: '' });
    }
    this.setState({ household_members: value, household_details: members })
  }

  handleHouseholdMemberAgeChange = (event, index) => {
    const { household_details } = this.state
    const { value } = event.target
    let household_details_copy = household_details
    let object = household_details_copy[index]
    object.age = value
    this.setState({ household_details: household_details_copy })
  }

  handleHouseholdMemberGenderChange = (event, index) => {
    const { household_details } = this.state
    const { value } = event.target
    let household_details_copy = household_details
    let object = household_details_copy[index]
    object.gender = value
    this.setState({ household_details: household_details_copy })
  }

  handleCheckListChange = (event) => {
    const { symptoms } = this.state;
    const { value, checked } = event.target
    let symptomsCopy = symptoms

    if (checked) {
      symptomsCopy.push(value)
    } else {
      let remove = symptomsCopy.filter((data) => {
        return data !== value
      })
      symptomsCopy = remove
    }

    this.setState({ symptoms: symptomsCopy })
  }

  renderHouseholdDetails(num) {
    if (num > 0) {
      return (
        <MDBRow className="my-2">
          <MDBCol className="my-2">
            <label>Household Details</label>
            {Array.from(Array(parseFloat(num)), (item, index) => (
              <MDBRow className="my-2" key={index}>
                <MDBCol md='1' className="grey-text my-auto">
                  <div>
                    <input
                      name={index}
                      type="text"
                      placeholder='Age'
                      className="form-control"
                      style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
                      onBlur={(event) => this.handleHouseholdMemberAgeChange(event, index)}
                    />
                  </div>
                </MDBCol>
                <MDBCol md='3' className="grey-text my-2">
                  <select
                    style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
                    name="gender"
                    type="text"
                    className="browser-default custom-select"
                    onBlur={(event) => this.handleHouseholdMemberGenderChange(event, index)}
                  >
                    <option value="" disabled selected hidden >Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </MDBCol>
              </MDBRow>
            ))}
          </MDBCol>
        </MDBRow>
      )
    }
  }

  handleLoading = (delay, func) => {
    return new Promise(() => {
      setTimeout(() => {
        func();
      }, delay)
    })
  }
  handleAddPersonTryCatch = (state, dataToken, dataTown) => {
    const data = state
    const token = dataToken
    const town = dataTown
    return new Promise(() => {
      try {
        console.log("triggetred")
        let response = axios({
          method: 'post',
          url: 'https://ncov-monitoring-api.herokuapp.com/api/person',
          headers: {
            "Authorization": token,
            "area_code": town.code,
          },
          data: {
            name: data.name,
            age: data.age,
            gender: data.gender,
            nationality: data.nationality,
            occupation: data.occupation,
            contact_number: data.contact_number,
            office_address: data.office_address,
            email: data.email,
            area_code: town.code,
            house_num: data.house_num,
            street: data.street,
            brgy: data.brgy,
            town_city: data.town_city,
            hw_id: data.hw_id,
            date_visit: data.date_visit,
            visit_purpose: data.visit_purpose,
            v_type: data.v_type,
            v_plate_num: data.v_plate_num,
            v_bus_seat_num: data.v_bus_seat_num,
            hometown_visits: data.hometown_visits,
            foreign_visits: data.foreign_visits,
            household_members: data.household_members,
            household_details: JSON.stringify(data.household_details),
            symptoms: JSON.stringify(data.symptoms),
            signature: '',
          }
        });
        console.log(response)
        this.setState({ success: !data.success, modal: !data.modal })
        setTimeout(() => {
          this.setState({ success: false })
          console.log('success')
        }, 5000)
      } catch (err) {
        console.log(err)
        this.setState({ modal: !data.modal, error: !data.error, })
        console.log(data.loading)
      }
    })
  }

  handleAddPerson = () => {
    const token = localStorage.getItem('auth-token')
    const data = this.state;
    const town = towns.find(town =>
      town.location === data.town_city
    )
    const brgy = towns.find(brgy =>
      brgy.barangay_list === data.brgy
    )
    this.handleToggleAddPersonModal()
    this.setState((state) => ({ loading: !state.loading }))
    // this.handleLoading(1000, () => this.setState((state) => ({loading: !state.loading})))
    this.handleLoading(2000, () => this.setState((state) => ({ loading: false })))
    this.handleAddPersonTryCatch(data, token, town, brgy)

  }

  renderModal = () => {
    const { success, addPersonModal, loading, modal } = this.state
    if (modal) {
      return (
        <MDBModal isOpen={this.state.modal} centered>
          <MDBModalHeader toggle={() => this.toggle()}>Add Person</MDBModalHeader>
          <MDBContainer>
            <MDBCol>
              <p>Are you sure you want to add this data?</p>
            </MDBCol>
            <MDBCol className='text-center'>
              <MDBBtn flat className='height-30 bg-green text-white p-0 width-120 height-35 br-5' onClick={() => this.handleAddPerson()}>YES</MDBBtn>
              <MDBBtn flat className='height-30 bg-red text-white p-0 width-120 height-35 br-5' onClick={() => this.toggle()}>NO</MDBBtn>
            </MDBCol>
          </MDBContainer>
        </MDBModal>
      )
    } else if (loading) {
      return (
        <MDBModal isOpen={this.state.loading} centered>
          <div className="add-person-loading-screen">
            <BallSpinFadeLoader
              loading={this.state.loading}
              color="#3AAFA9"
            />
          </div>
        </MDBModal>
      )
    } else if (success) {
      return (
        <MDBModal isOpen={this.state.success} centered>
          <MDBModalHeader style={{ backgroundColor: "#52afa9" }} toggle={() => this.toggleSuccess()}>Add Person</MDBModalHeader>
          <MDBModalBody>
            <MDBRow className="justify-content-center ">
              <MDBCol md='7' className="text-right">
                <h3>Successful! </h3>
              </MDBCol>
              <MDBCol md='3' className="text-left">
                <MDBIcon icon="check-square" size="2x" style={{ color: "#52afa9" }} />
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>
      )
    } else {
      return (
        <MDBModal isOpen={this.state.error} centered >
          <MDBModalHeader className="text-white" style={{ backgroundColor: "#52afa9" }} toggle={() => this.toggleError()}>Add Person</MDBModalHeader>
          <MDBModalBody>
            <MDBRow className="justify-content-center ">
              <MDBCol md='7' className="text-right">
                <h3>Failed to add data!</h3>
              </MDBCol>
              <MDBCol md='3' className="text-left">
                <MDBIcon icon="window-close" size="2x" style={{ color: "#52afa9" }} />
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBCol className='text-center'>
              <MDBBtn flat className='height-30 bg-green text-white p-0 width-120 height-35 br-5' onClick={() => this.handleAddPerson()}>RETRY</MDBBtn>
              <MDBBtn flat className='height-30 bg-red text-white p-0 width-120 height-35 br-5' onClick={() => this.toggleError()}>CANCEL</MDBBtn>
            </MDBCol>
          </MDBModalFooter>
        </MDBModal>
      )
    }
  }

  handleToggleAddPersonModal = () => {
    const { addPersonModal, modal } = this.state
    this.setState({ addPersonModal: !addPersonModal, modal: !modal })
  }

  render() {
    console.log(this.state.loading)
    const { household_members } = this.state;
    return (
      <MDBContainer fluid className='card p-0'>
        <MDBRow>
          <MDBCol>
            <form>
              <MDBContainer>
                <MDBRow className='mb-3'>
                  <MDBCol md='4'>
                  </MDBCol>
                  <MDBCol md='4' className='d-flex'>
                    <h2 className="font-weight-bold text-center my-auto mx-auto">Add PUM</h2>
                  </MDBCol>
                  <MDBCol md='4' className='text-right' >
                    <MDBNavLink to='/' className=''>
                      <MDBIcon icon="times" />
                    </MDBNavLink>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="my-2">
                  <MDBCol className="grey-text my-2">
                    <input
                      name="name"
                      type="text"
                      placeholder='Name'
                      className="form-control"
                      value={this.state.name}
                      onChange={this.handleChange}
                    />
                  </MDBCol>
                  <MDBCol className="grey-text my-2">
                    <select
                      name="nationality"
                      type="text"
                      placeholder='Nationality'
                      className="form-control"
                      value={this.state.nationality}
                      onChange={this.handleChange}
                    >
                      <option value="" disabled selected hidden>Nationality</option>
                      {nationalities.map((nationality, index) => {
                        return (
                          <option value={nationality.nationality} key={index}>{nationality.nationality}</option>
                        )
                      })}
                    </select>
                  </MDBCol>
                </MDBRow>
                <MDBRow className='my-2'>
                  <MDBCol className="grey-text my-2">
                    <input
                      name="age"
                      type="text"
                      placeholder='Age'
                      className="form-control"
                      value={this.state.age}
                      onChange={this.handleChange}
                    />
                  </MDBCol>
                  <MDBCol className="grey-text my-2">
                    <select
                      name="gender"
                      type="text"
                      className="browser-default custom-select"
                      value={this.state.gender}
                      onChange={this.handleChange}
                    >
                      <option value="" disabled selected hidden >Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </MDBCol>
                  <MDBCol className="grey-text my-2">
                    <input
                      name="contact_number"
                      type="text"
                      placeholder='Contact Number'
                      className="form-control"
                      value={this.state.contact_number}
                      onChange={this.handleChange}
                    />
                  </MDBCol>
                  <MDBCol className="grey-text my-2">
                    <input
                      name="email"
                      type="email"
                      placeholder='Email'
                      className="form-control"
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow className='my-2'>
                  <MDBCol className="grey-text">
                    <input
                      name="house_num"
                      type="text"
                      placeholder='House Number'
                      className="form-control"
                      value={this.state.house_num}
                      onChange={this.handleChange}
                    />
                  </MDBCol>
                  <MDBCol className="grey-text">
                    <input
                      name="street"
                      type="text"
                      placeholder='Street'
                      className="form-control"
                      value={this.state.street}
                      onChange={this.handleChange}
                    />
                  </MDBCol>
                  <MDBCol className="grey-text">
                    <select
                      name="brgy"
                      type="text"
                      placeholder='Barangay'
                      className="form-control"
                      value={this.state.brgy}
                      onChange={this.handleChange}
                    >
                      <option value="" disabled selected hidden>Barangay</option>
                        {towns[this.state.townIndex].barangay_list.map((brgy, index) => {
                        return (
                          <option value={brgy} key={index}>{brgy}</option>
                          // console.log('brgy',brgy, index)
                        )
                      })}
                    </select>
                  </MDBCol>
                  <MDBCol className="grey-text">
                    <select
                      name="town_city"
                      type="text"
                      placeholder='Town'
                      className="form-control"
                      value={this.state.town_city}
                      onChange={this.handleChange}
                    >
                      <option value="" disabled selected hidden>Town</option>
                      {towns.map((town, index) => {
                        return (
                          <option value={town.location} key={index}>{town.location.toUpperCase()}</option>
                        )
                      })}
                    </select>
                  </MDBCol>
                </MDBRow>
                <MDBRow className='my-2'>
                  <MDBCol className="grey-text my-2">
                    <input
                      name="occupation"
                      type="text"
                      placeholder='Work'
                      className="form-control"
                      value={this.state.occupation}
                      onChange={this.handleChange}
                    />
                  </MDBCol>
                  <MDBCol className="grey-text my-2">
                    <input
                      name="office_address"
                      type="text"
                      placeholder='Office Address'
                      className="form-control"
                      value={this.state.office_address}
                      onChange={this.handleChange}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow className='my-2'>
                  <MDBCol md='6' className="grey-text my-2">
                    <input
                      name="date_visit"
                      placeholder="Date of Visit"
                      className="form-control"
                      type="text"
                      value={this.state.date_visit}
                      onChange={this.handleChange}
                      onFocus={(e) => e.target.type = 'date'}
                      onBlur={(e) => e.target.type = 'text'}
                    />
                  </MDBCol>
                  <MDBCol md='6' className="grey-text my-2">
                    <input
                      name="visit_purpose"
                      type="text"
                      placeholder='Purpose of Visit'
                      className="form-control"
                      value={this.state.visit_purpose}
                      onChange={this.handleChange}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow className='my-2'>
                  <MDBCol className="grey-text">
                    <input
                      name='v_type'
                      type='text'
                      placeholder='Vehicle Type'
                      className="form-control"
                      value={this.state.v_type}
                      onChange={this.handleChange}
                    />
                  </MDBCol>
                  <MDBCol className="grey-text">
                    <input
                      name='v_plate_num'
                      type='text'
                      placeholder='Vehicle Plate Number'
                      className="form-control"
                      value={this.state.v_plate_num}
                      onChange={this.handleChange}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol m='6' className="grey-text my-2">
                    <input
                      name="v_bus_seat_num"
                      type="number"
                      placeholder="Seat Number"
                      className="form-control"
                      value={this.state.v_bus_seat_num}
                      onChange={this.handleChange}
                    />
                  </MDBCol>
                  <MDBCol m='6' className="grey-text my-2">
                    <input
                      name="hometown_visits"
                      type="text"
                      placeholder="Place of Destination"
                      className="form-control"
                      value={this.state.hometown_visits}
                      onChange={this.handleChange}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow className='my-2'>
                  <MDBCol className="grey-text my-2">
                    <input
                      name="foreign_visits"
                      type="text"
                      placeholder='Foreign Countries or Other Region Visited'
                      className="form-control"
                      value={this.state.foreign_visits}
                      onChange={this.handleChange}
                    />
                  </MDBCol>
                  <MDBCol className="grey-text my-2">
                    <input
                      name="household_members"
                      type="number"
                      placeholder='Number of Household Members'
                      className="form-control"
                      value={this.state.household_members}
                      onChange={(value) => this.handleHouseholdMembersChange(value)}
                    />
                  </MDBCol>
                </MDBRow>
                {this.renderHouseholdDetails(household_members)}
                <MDBRow className='my-4'>
                  <MDBCol md='12'>
                    <label>Symptom's Checklist</label>
                    <MDBRow>
                      <MDBCol md='2' className="my-auto">
                        <div>
                          <input
                            className='mr-2'
                            name="fever"
                            type="checkbox"
                            value="fever"
                            onChange={(event) => this.handleCheckListChange(event)}
                          />
                          <label htmlFor="fever"> Fever</label>
                        </div>
                        <div>
                          <input
                            className='mr-2'
                            name="cough"
                            type="checkbox"
                            value="cough"
                            onChange={(event) => this.handleCheckListChange(event)}
                          />
                          <label htmlFor="cough"> Cough</label>
                        </div>
                      </MDBCol>
                      <MDBCol md='2' className="my-auto">
                        <div>
                          <input
                            className='mr-2'
                            name="cold"
                            type="checkbox"
                            value="cold"
                            onChange={(event) => this.handleCheckListChange(event)}
                          />
                          <label htmlFor="cold"> Cold</label>
                        </div>
                        <div>
                          <input
                            className='mr-2'
                            name="sore_throat"
                            type="checkbox"
                            value="sore throat"
                            onChange={(event) => this.handleCheckListChange(event)}
                          />
                          <label htmlFor="cold">Sore Throat</label>
                        </div>
                      </MDBCol>
                      <MDBCol md='3' className="my-auto">
                        <div>
                          <input
                            className='mr-2'
                            name="difficulty_in_breathing"
                            type="checkbox"
                            value="difficulty in breathing"
                            onChange={(event) => this.handleCheckListChange(event)}
                          />
                          <label htmlFor="cold">Difficulty in Breathing</label>
                        </div>
                        <div>
                          <input
                            className='mr-2'
                            name="diarrhea"
                            type="checkbox"
                            value="diarrhea"
                            onChange={(event) => this.handleCheckListChange(event)}
                          />
                          <label htmlFor="cold">Diarrhea</label>
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
                <div className="text-center">
                  <MDBBtn color="primary" onClick={this.handleToggleAddPersonModal}>Add</MDBBtn>
                </div>
              </MDBContainer>
            </form>
          </MDBCol>
        </MDBRow>
        {this.renderModal()}
      </MDBContainer >
    );
  }
}





export default index;