import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom'
// import Auth from '../../api_connections/Auth';
import { connect } from 'react-redux';
import { MDBContainer, MDBCol, MDBCard, MDBRow, MDBCardBody, MDBBtn } from 'mdbreact';
import { login } from '../../reducer/Reducer'
import axios from 'axios';
const logo = require('../../assets/images/admin-login/admin-login-logo.png')
export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areaCode: '',
      redirectToDashboard: false,
      password: '',
      invalid: false,
      unknown: false,
      loading: false
    };
  }

  // componentDidMount() {
  //   let token = localStorage.getItem('token')
  //   if (token !== null) {
  //     window.location.href = '/'
  //   }
  // }


  handleLogin = async (e) => {
    this.setState({ loading: true })
    const { areaCode, password } = this.state;
    e.preventDefault();
    try {
      let responseToken = await axios.post('https://ncov-monitoring-api.herokuapp.com/api/user/admin/auth', {
        area_code: areaCode,
        password: password
      })
      console.log(responseToken);
      if (responseToken.data.status == "success") {
        this.setState({ loading: false })
        await localStorage.setItem('auth-token', responseToken.data.token)
        await localStorage.setItem('user-level', responseToken.data.data.user_level)
        await localStorage.setItem('area_code', responseToken.data.data.area_code)
        await localStorage.setItem('user_name', responseToken.data.data.first_name + " " + responseToken.data.data.last_name)
        window.location.replace('/admin/dashboard')
      } else {
        // alert(responseToken.data.message);
        this.setState({ invalid: responseToken.data.message, loading: false })
      }
    } catch (error) {
      // console.log(error)
      this.setState({ unknown: true, loading: false })
    };

  }

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  render() {
    let { redirectToDashboard, areaCode, password } = this.state;
    // if (redirectToDashboard) return <Redirect to='/dashboard' />;
    return (
      <MDBContainer fluid className='px-0 py-0'>
        <MDBRow className='bg-login' style={{ height: '-webkit-fill-available' }}>
          <MDBCol className='w-50 d-flex px-0'>
            <MDBCard className='mx-auto my-auto card-container'>
              <MDBCardBody className='my-5'>
                <MDBCol className='d-flex mb-5'>
                  <img className='mx-auto' src={logo} width={100} height={100} />
                </MDBCol>
                <MDBContainer fluid className='w-75'>
                  <MDBRow>
                    <MDBCol md="12">
                      <form>
                        <input
                          name="areaCode"
                          type="text"
                          placeholder='Username'
                          className="form-control"
                          value={areaCode}
                          onChange={this.handleChange}
                        />
                        <br />
                        <input
                          name="password"
                          type="password"
                          id="defaultFormLoginPasswordEx"
                          placeholder='Password'
                          className="form-control"
                          value={password}
                          onChange={this.handleChange}
                        />
                        <br></br>
                        {this.state.invalid != "" ? <p className='text-white'><i className="fa fa-exclamation-circle"></i> {this.state.invalid}</p> : null}
                        <MDBRow className="text-center px-0 mt-4">
                          <MDBBtn onClick={(e) => this.handleLogin(e)} className='rounded-pill w-100 admin-btn-login shadow-none text-white' color='#2B7A77'>
                            {this.state.loading ? <div className="spinner-grow text-info" role="status">
                              <span className="sr-only">Loading...</span>
                            </div> : "Login"}
                          </MDBBtn>
                        </MDBRow>
                        <MDBRow className="text-center px-0 mt-4">
                          {/* <MDBLink className='mx-auto text-white'>Forgot your password?</MDBLink> */}
                        </MDBRow>
                      </form>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

// export default index;
const mapStateToProps = state => {
  return {
    loginState: state.loginState,
  };
};

const mapDispatchToProps = {
  login

};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(index)