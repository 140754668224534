let total = 5000
let list = []
let symptomsList = ["fever", "cold", "difficulty in breathing", "cough", "sore throat", "diarrhea"];
let towns = [
  {
    "location": "Agno",
    "code": 2408
  },
  {
    "location": "Aguilar",
    "code": 2415
  },
  {
    "location": "Alaminos City",
    "code": 2404
  },
  {
    "location": "Alcala",
    "code": 2425
  },
  {
    "location": "Anda",
    "code": 2405
  },
  {
    "location": "Asingan",
    "code": 2439
  },
  {
    "location": "Balungao",
    "code": 2442
  },
  {
    "location": "Bani",
    "code": 2407
  },
  {
    "location": "Basista",
    "code": 2422
  },
  {
    "location": "Bautista",
    "code": 2424
  },
  {
    "location": "Bayambang",
    "code": 2423
  },
  {
    "location": "Binalonan",
    "code": 2436
  },
  {
    "location": "Binmaley",
    "code": 2417
  },
  {
    "location": "Bolinao",
    "code": 2406
  },
  {
    "location": "Bugallon",
    "code": 2416
  },
  {
    "location": "Burgos",
    "code": 2410
  },
  {
    "location": "Calasiao",
    "code": 2418
  },
  {
    "location": "Dagupan City",
    "code": 2400
  },
  {
    "location": "Dasol",
    "code": 2411
  },
  {
    "location": "Infanta",
    "code": 2412
  },
  {
    "location": "Labrador",
    "code": 2402
  },
  {
    "location": "Laoac",
    "code": 2437
  },
  {
    "location": "Lingayen",
    "code": 2401
  },
  {
    "location": "Mabini",
    "code": 2409
  },
  {
    "location": "Malasiqui",
    "code": 2421
  },
  {
    "location": "Manaoag",
    "code": 2430
  },
  {
    "location": "Mangaldan",
    "code": 2432
  },
  {
    "location": "Mangatarem",
    "code": 2413
  },
  {
    "location": "Mapandan",
    "code": 2429
  },
  {
    "location": "Natividad",
    "code": 2446
  },
  {
    "location": "Pozorrubio",
    "code": 2435
  },
  {
    "location": "Rosales",
    "code": 2441
  },
  {
    "location": "San Carlos City",
    "code": 2420
  },
  {
    "location": "San Fabian",
    "code": 2433
  },
  {
    "location": "San Jacinto",
    "code": 2431
  },
  {
    "location": "San Manuel",
    "code": 2438
  },
  {
    "location": "San Nicolas",
    "code": 2447
  },
  {
    "location": "San Quintin",
    "code": 2444
  },
  {
    "location": "Santa Barbara",
    "code": 2419
  },
  {
    "location": "Santa Maria",
    "code": 2440
  },
  {
    "location": "Santo Tomas",
    "code": 2426
  },
  {
    "location": "Sison",
    "code": 2434
  },
  {
    "location": "Sual",
    "code": 2403
  },
  {
    "location": "Tayug",
    "code": 2445
  },
  {
    "location": "Umingan",
    "code": 2443
  },
  {
    "location": "Urbiztondo",
    "code": 2414
  },
  {
    "location": "Urdaneta",
    "code": 2428
  },
  {
    "location": "Villasis",
    "code": 2427
  }
];

function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

function householdDetails() {
  let res = [];
  let r = Math.floor(Math.random(1) * 9);
  for (var x = 0; x < r; x++) {
    res.push({
      age: Math.floor(Math.random(1) * 60),
      gender: (Math.floor(Math.random()) == 0) ? "Male" : "Female"
    });
  }
  return res;
}

for (let p = 1; p <= total; p++) {
  let town = shuffle(towns).slice(0, 1)[0]
  // let house = (p % 2) ? JSON.stringify([{ "age": Math.floor(Math.random() * 90).toString(), "gender": shuffle(["Female", "Male"]).slice(0, 1).toString() }]) : "[]";
  let symp = (p % 2) ? JSON.stringify(shuffle(symptomsList).slice(0, 1)) : "[]";
  list.push({
    name: "Tony Stark" + p,
    age: 21,
    gender: shuffle(["Female", "Male"]).slice(0, 1).toString(),
    nationality: "Filipino",
    occupation: "Tambay",
    contact_number: "0908070605",
    office_address: "Unit 10 RMB Bldg. Maramba Street, Lingayen Pangasinan",
    email: "email@test.com",
    area_code: town.code,
    house_num: "069",
    street: "Casulming St",
    brgy: "Maniboc",
    town_city: town.location,
    hw_id: "",
    date_visit: "Mar 10, 2020",
    visit_purpose: "tamang gala lang",
    v_type: "car",
    v_plate_num: "ABC123",
    v_bus_seat_num: 1,
    hometown_visits: "bayan",
    household_members: 0,
    household_details: "[]",
    foreign_visits: "wala, poor lang ako",
    symptoms: symp.toString(),
    signature: "image/base64"
  })
}
export const persons = list;