import React, { Component } from 'react';
import { connect } from 'react-redux';
import SimpleCrypt from './../components/simpleEncrypt';
import ERequest from './../components/request';
import AdmitModal from './../components/admitModalContent';
import Modal from './../components/modal';
import CustomTable from './../components/table';
import PLoader from './../components/processLoader';
import PersonsFullInfo from './../components/personInfoHandler';

export default class PUM extends Component{

  setState(states){
    this.props.setState(states)
  }

  submitAdmission = async () => {
    let data = this.props.data.toAdmit;
    if (this.props.data.remarks && this.props.data.admitStage && this.props.data.facility) {
      this.setState({ processing: true, pumModal: false });
      let admission = await ERequest('get', this.props.data.admitUrl + data.p_id, null, {}, {});
      let admissionID = admission.data.data.id;
      let res = await ERequest('put', this.props.data.admitUrl + admissionID, true, {}, {
        p_id: data.p_id,
        stage: this.props.data.admitStage,
        remarks: this.props.data.remarks,
        facility: this.props.data.facility
      });
      console.log(data.p_id, admission, res, this.props.data.facility);
      this.setState({ processed: true });
      window.location.reload();
    } else {
      alert("Please complete all fields!");
    }
  }

  table(){
    return CustomTable({
      id: "table1",
      title: 'Persons Under Monitoring',
      columns:
        [
          { title: 'Name', field: 'name' },
          { title: 'Quarantine Days', field: 'days' },
          { title: 'Town', field: 'town_city' },
          { title: 'Gender', field: 'gender' },
          { title: 'Age', field: 'age' },
          { title: 'Nationality', field: 'nationality' },
        ],
      data: this.props.data.tableData.pumQR || [],
      buttons: [
        {
          tooltip: 'Admit Person', icon: 'fa-hospital', color: 'btn-default', onClick: (e) => {
            let persondata = JSON.parse(e.currentTarget.dataset.metadata);
            this.setState({ pumModal: !this.props.data.pumModal, toAdmit: persondata });
          }
        },
        {
          tooltip: 'Edit Informations', icon: 'fa-edit', color: '', onClick: (e) => {
            e.preventDefault();
            let persondata = JSON.parse(e.currentTarget.dataset.metadata);
            let s = SimpleCrypt("enc", persondata.id);
            console.log(persondata.id, s, SimpleCrypt("dec", s));
            window.location.href = '/admin/monitoring/#/edit_pum/' + s
          }
        }
      ],
      onSearch: async (term, tableupdate) => {
        this.handleSearch(tableupdate, term, "PUM-QR");
      },
      onChangeBatch: (dir, tableupdate) => {
        this.handleOnChangeBatch(tableupdate, dir, "PUM-QR");
      }
    })
  }

  handleSearch = async (tableupdate, term, stage) => {
    let res = await ERequest("get", this.props.data.searchUrl, null, { term: term, page: this.props.data.page, size: this.props.data.size, stage: stage }, {});
    let data = await PersonsFullInfo(this.props.data, { stage: stage, page: this.props.data.page, size: this.props.data.size }, res, false) || [];
    console.log("Search Result: " + data);
    tableupdate.setAttribute("data-result", JSON.stringify(data));
    tableupdate.click();
  }

  handleOnChangeBatch = async (tableupdate, dir, stage) => {
    let params = { stage: stage, page: this.props.data.page, size: this.props.data.size }
    let page = eval(`${this.props.data.page} ${dir} 1`);
    if (page >= 0) { this.setState({ page: page }); }
    let res = await ERequest("get", this.props.data.url, null, params, {});
    let data = await PersonsFullInfo(this.props.data, params, res) || [];
    tableupdate.setAttribute("data-result", JSON.stringify(data));
    tableupdate.click();
  }

  admitModal(){
    return Modal({
      show: this.props.data.pumModal,
      size: "md",
      title: "Confirm Admission",
      backdrop: true,
      content: this.admitModalContent(),
      footer: [{
          class: 'btn-sm bg-danger', text: 'CANCEL', onClick: () => { this.setState({ pumModal: false }) }
        }, {
          class: 'btn-sm bg-primary', text: 'ADMIT', onClick: this.submitAdmission
        }]
    })
  }

  admitModalContent = () => {
    let data = this.props.data.toAdmit;
    let THIS = this;
    if (!data.id) {
      return (<div></div>);
    } else {
      return AdmitModal({
        data: data,
        onSelect: (value) => {
          THIS.setState({ admitStage: value });
        },
        onChangeText: (target, value) => {
          if (target == "facility") {
            THIS.setState({ facility: value });
          } else if (target == "remarks") {
            THIS.setState({ remarks: value });
          }
        }
      });
    }
  }

  render() {
    return (
      <div>
        {this.table()}
        {this.admitModal()}
      </div>
    )
  }
}