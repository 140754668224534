import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBContainer, MDBCol, MDBRow, MDBCard, MDBCardBody, MDBIcon } from 'mdbreact';
import { Doughnut } from 'react-chartjs-2';
import { MDBListGroup, MDBListGroupItem, MDBBadge } from 'mdbreact';
import Moment from 'react-moment';
import axios from 'axios';
import { CSVLink } from "react-csv";
import '../../assets/styling/SchoolDirectory.css'
import LoadingScreen from '../../components/loadingScreen';
import Modal from '../admission/components/modal';
import CustomTable from '../admission/components/table';
import ERequest from '../admission/components/request';

export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      loading: true,
    };
  }

  componentDidMount = async () => {
    let lvl = localStorage.getItem("user-level");
    let acode = localStorage.getItem("area_code");
    let filter = lvl == "LA" ? acode : '';
    let logs = await ERequest("get", "https://ncov-monitoring-api.herokuapp.com/api/logs/" + filter, null, {}, {});
    if (logs.data.status == "success") {
      this.setState({
        data: logs.data.data,
        loading: false
      })
    }else{
      this.setState({
        data: logs.data.data,
        loading: false
      })
    }
  }

  renderLogsTable() {
    if(this.state.data.length > 0){
      return this.state.data.map((log, index) => {
        return <tr>
          <td>{log.id}</td>
          <td>{log.user_id}</td>
          <td>{log.p_id}</td>
          <td>{log.action}</td>
          <td>{log.remarks}</td>
          <td>{new Date(log.createdAt).toDateString() + " " + new Date(log.createdAt).toLocaleTimeString()}</td>
        </tr>
      })
    }else{
      return <tr>
        <td colSpan="6" className="text-center">No Record of Logs</td>
      </tr>
    }
    
  }

  renderMainDiv() {
    const { monitoringData } = this.state;
    return (
      <div className="container px-0">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4>User Logs</h4>
              </div>
              <div className="card-body">
                {this.state.data.length > 0 ? <CSVLink  style={{float: 'right'}} data={this.state.data} filename={localStorage.getItem("area_code") + "-logs.csv"}>Download CSV</CSVLink>: null}
                <br></br>
                <table className="table table-striped table-bordered mt-3">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Area Code</th>
                      <th>Prior</th>
                      <th>Action</th>
                      <th>Remarks / Details</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderLogsTable()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { loading } = this.state;
    if (loading) {
      return <LoadingScreen />
    } else {
      return this.renderMainDiv()
    }
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(index);
