import React, { Component } from 'react';
import { connect } from 'react-redux';
import SimpleCrypt from './../components/simpleEncrypt';
import ERequest from './../components/request';
import AdmitModal from './../components/admitModalContent';
import Modal from './../components/modal';
import CustomTable from './../components/table';
import PLoader from './../components/processLoader';
import PersonsFullInfo from './../components/personInfoHandler';

export default class PUM extends Component{

  setState(states){
    this.props.setState(states)
  }

  table(){
    return CustomTable({
      id: "table3",
      title: 'Confirmed Cases',
      columns:
        [
          { title: 'ID', field: 'p_id' },
          { title: 'Name', field: 'name' },
          { title: 'Town', field: 'town_city' },
          { title: 'Gender', field: 'gender' },
          { title: 'Age', field: 'age' },
          { title: 'Nationality', field: 'nationality' },
          { title: 'Facility', field: 'facility' },
          { title: 'Date of Infection', field: 'lastupdate' }
        ],
      data: this.props.data.tableData.confirmed || [],
      buttons: [
        {
          tooltip: 'View Personal Informations', icon: 'fa-edit', color: '', onClick: (e) => {
            e.preventDefault();
            let persondata = JSON.parse(e.currentTarget.dataset.metadata);
            let s = SimpleCrypt("enc", persondata.id);
            console.log(persondata.id, s, SimpleCrypt("dec", s));
            window.location.href = '/admin/monitoring/#/edit_pum/' + s
          }
        }, {
          tooltip: 'Update Status', icon: 'fa-edit', color: 'btn-danger', onClick: (e) => {
            let persondata = JSON.parse(e.currentTarget.dataset.metadata);
            this.setState({ confirmMdal: !this.props.data.confirmMdal, ctoAdmit: persondata });
          }
        }
      ],
      onSearch: async (term, tableupdate) => {
        this.handleSearch(tableupdate, term, "CONFIRMED");
      },
      onChangeBatch: (dir, tableupdate) => {
        this.handleOnChangeBatch(tableupdate, dir, "CONFIRMED");
      }
    })
  }

  handleSearch = async (tableupdate, term, stage) => {
    let res = await ERequest("get", this.props.data.searchUrl, null, { term: term, page: this.props.data.page, size: this.props.data.size, stage: stage }, {});
    let data = await PersonsFullInfo(this.props.data, { stage: stage, page: this.props.data.page, size: this.props.data.size }, res, false) || [];
    console.log("Search Result: " + data);
    tableupdate.setAttribute("data-result", JSON.stringify(data));
    tableupdate.click();
  }

  handleOnChangeBatch = async (tableupdate, dir, stage) => {
    let params = { stage: stage, page: this.props.data.page, size: this.props.data.size }
    let page = eval(`${this.props.data.page} ${dir} 1`);
    if (page >= 0) { this.setState({ page: page }); }
    let res = await ERequest("get", this.props.data.url, null, params, {});
    let data = await PersonsFullInfo(this.props.data, params, res) || [];
    tableupdate.setAttribute("data-result", JSON.stringify(data));
    tableupdate.click();
  }

  render() {
    return (
      <div>
        {this.table()}
        {Modal({
          show: this.props.data.confirmMdal,
          size: "md",
          title: "Update Status",
          backdrop: true,
          content: <div>
            <label className="text-black-50">Mark as</label>
            <select className="form-control" onChange={(e) => { this.setState({ cadmitStage: e.currentTarget.value }) }}>
              <option disabled selected value="">Select Status</option>
              <option value="RC">Recovered</option>
              <option value="DEAD">Died</option>
            </select>
            <br></br>
            <label className="text-black-50">Current Facility</label>
            <input type="text" className="form-control" placeholder="Facility or Hospital" placeholder={this.props.data.ctoAdmit.facility} onChange={(e) => { this.setState({ cfacility: e.currentTarget.value }) }} ></input>
            <br></br>
            <label className="text-black-50">Remarks</label>
            <textarea rows="3" className="form-control" placeholder="" onChange={(e) => { this.setState({ cremarks: e.currentTarget.value }) }}></textarea>
          </div>,
          footer: [{
            class: '', text: 'CANCEL', onClick: () => { this.setState({ confirmMdal: false }) }
          }, {
            class: '', text: 'ADMIT', onClick: async () => {
              let data = this.props.data.ctoAdmit;
              let facility = this.props.data.cfacility == '' ? data.facility : this.props.data.cfacility
              let stage = this.props.data.cadmitStage == '' ? data.status : this.props.data.cadmitStage
              if (this.props.data.cremarks) {
                this.setState({ processing: true, confirmMdal: false });
                let admission = await ERequest('get', this.state.admitUrl + data.p_id, null, {}, {});
                let admissionID = admission.data.data.id;
                let res = await ERequest('put', this.props.data.admitUrl + admissionID, true, {}, {
                  p_id: data.p_id,
                  stage: stage,
                  remarks: this.props.data.cremarks,
                  facility: facility
                });
                console.log(data.p_id, admission, res, facility);
                this.setState({ processed: true });
                window.location.reload();
              } else {
                alert("Remarks is required!");
              }
            }
          }]
        })}
      </div>
    )
  }
}