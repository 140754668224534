import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBContainer, MDBCol, MDBRow, MDBCard, MDBCardBody, MDBIcon } from 'mdbreact';
import { Doughnut } from 'react-chartjs-2';
import { MDBListGroup, MDBListGroupItem, MDBBadge } from 'mdbreact';
import Moment from 'react-moment';
import axios from 'axios';
import '../../assets/styling/landing.css'
import '../../index.css'
import LoadingScreen from '../../components/loadingScreen';
// import Map from './map';
import { Coords } from '../../components/coords';
import MapWithAMarkerClusterer from './map';
import Modal from '../admission/components/modal';
import { Url } from 'devextreme-react/chart';

export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coordinates: [],
      dataDoughnut: {},
      updates: null,
      monitoringData: null,
      loading: true,
      markers: {},
      mapConfig: {},
      filterModal: false,
      toFilter: '',
      targetPlace: '',
    };
  }

  componentDidMount = async () => {
    try {

      let coordinates = await this.getPangasinanCoordinates();
      this.setState({coordinates: coordinates});

      fetch('https://ncov-monitoring-api.herokuapp.com/api/monitoring/data/' + this.getTargetFilterPlace())
        .then(response => response.json())
        .then(res => {
          let mapData = this.getBrgyCoordinates(res.map.data)
          this.setState({ markers: mapData, mapConfig: res.map, updates: res, loading: false })
        })
    } catch (error) {
      console.log(error);
    }
  }

  getTargetFilterPlace(){
    let coords = this.state.coordinates;
    let href = window.location.href;
    let areacode = href.includes("?area=") ? href.split("?area=")[1] : ''; 
    console.log(window.location.href, areacode)
    if(areacode == "all"){
      window.location.href = "/"
    }
    if(areacode != ''){
      let town = coords.find(function(loc){return loc.code == areacode})
      this.setState({targetPlace: town.town})
    }
    return areacode
  }

  getPangasinanCoordinates = async () => {
    let result;
    await fetch('https://raw.githubusercontent.com/bpaltezo08/Pangasinan-Coords/master/data%20(8).json')
      .then(response => response.json())
      .then(res => {
        result = res;
    })
    return result || []
  }

  getBrgyCoordinates(data){
    let coords = this.state.coordinates;
    for(var x=0;x<data.length;x++){
      data[x].markerId = x
      let brgyname = data[x].brgy
      let town = coords.find(function(loc){return loc.code == data[x].area_code})
      let brgy = town.brgy.find(function(loc){return loc.brgy == brgyname.toUpperCase()})
      if(brgy != undefined){
        data[x].latitude = brgy.coords.lat
        data[x].longitude = brgy.coords.lng
      }else{
        data[x].latitude = town.coords.lat
        data[x].longitude = town.coords.lng
      }
    }
  return data;
  }

  renderMunicipalities = (areas) => {
    if (!areas) return null;
    if(areas.length > 0){
      return (areas.map((data, index) => {
        if(data.location){
        return <tr>
          <td>{data.location}</td>
          <td>{data.cases}</td>
        </tr>
        }else if(data.brgy){
                  return <tr>
          <td>{data.brgy}</td>
          <td>{data.cases}</td>
        </tr>
        }
      }));
    }else{
      return <tr>
        <td colSpan="2" className='text-center'>No Records</td>
      </tr>
    }
    
  }

  handleFilterOptions = () => {
    return Coords.map((area, index) => {
      return <option value={area.code}>{area.location}</option>
    });
  }

  renderMainDiv() {
    const { monitoringData } = this.state;
    return (
      <div className="container-fluid landing">
        <div className="row">
          <div className="col-md-12 px-0 col-xl-6">
            <div className="map">
              {
                this.state.markers.length > 0 ? <MapWithAMarkerClusterer markers={this.state.markers} config={this.state.mapConfig} /> : null
              }
            </div>
          </div>
          <div className="col-md-12 col-xl-6">
            <MDBContainer fluid className=' py-3' >
              <div className="row">
                <div className="col-md-5">
                  <div className="row">
                    <div className="col-md-12">
                      <MDBCard className="borderLeftAdmitted border-top-0 border-right border-bottom-0 shadow-sm">
                        <div className="card-header">
                          <MDBRow>
                            <MDBCol className='col-9 pr-0'>
                              <h4 className='my-auto font-weight-bold'>Admitted</h4>
                            </MDBCol>
                            <MDBCol className='col-3 d-flex px-0'>
                              <MDBIcon className='mx-auto' size='2x' icon="user-check" />
                            </MDBCol>
                          </MDBRow>
                        </div>
                        <div className="card-body py-2">
                          <h2 className="text-info text-right m-0">{
                            this.state.updates.total_admission || 0
                          }</h2>
                        </div>
                      </MDBCard>
                    </div>
                    <div className="col-md-12 mt-3">
                      <MDBCard className="borderRecovered border-top-0 border-right border-bottom-0 shadow-sm">
                        <div className="card-header">
                          <MDBRow>
                            <MDBCol className='col-9 pr-0'>
                              <h4 className='my-auto font-weight-bold'>Recovered</h4>
                            </MDBCol>
                            <MDBCol className='col-3 d-flex px-0'>
                              <MDBIcon className='mx-auto' size='2x' icon="user-clock" />
                            </MDBCol>
                          </MDBRow>
                        </div>
                        <div className="card-body py-2">
                          <h2 className="text-success text-right m-0">{
                            this.state.updates.total_recovered || 0
                          }</h2>
                        </div>
                      </MDBCard>
                    </div>
                    <div className="col-md-12 mt-3">
                      <MDBCard className="borderConfirmed border-top-0 border-right border-bottom-0 shadow-sm">
                        <div className="card-header">
                          <MDBRow>
                            <MDBCol className='col-9 pr-0'>
                              <h4 className='my-auto font-weight-bold'>Confirmed</h4>
                            </MDBCol>
                            <MDBCol className='col-3 d-flex px-0'>
                              <MDBIcon className='mx-auto' size='2x' icon="user-plus" />
                            </MDBCol>
                          </MDBRow>
                        </div>
                        <div className="card-body py-2">
                          <h2 className="text-danger text-right m-0">{
                            this.state.updates.total_confirmed || 0
                          }</h2>
                        </div>
                      </MDBCard>
                    </div>
                    <div className="col-md-12 mt-3">
                      <MDBCard className="borderDeaths border-top-0 border-right border-bottom-0 shadow-sm">
                        <div className="card-header">
                          <MDBRow>
                            <MDBCol className='col-9 pr-0'>
                              <h4 className='my-auto font-weight-bold'>Deaths</h4>
                            </MDBCol>
                            <MDBCol className='col-3 d-flex px-0'>
                              <MDBIcon className='mx-auto' size='2x' icon="skull" />
                            </MDBCol>
                          </MDBRow>
                        </div>
                        <div className="card-body py-2">
                          <h2 className="text-right m-0">{
                            this.state.updates.total_dead || 0
                          }</h2>
                        </div>
                      </MDBCard>
                    </div>

                  </div>
                </div>
                <div className="col-md-7">
                  <div className="row h-100">
                    <div className="col-md-12">
                      <div className="card h-100">
                        <div className="card-header">
                          <h4 className='my-auto font-weight-bold'>Confirmed Cases</h4>
                        </div>
                        <div className="card-body scrollable-table h-300 ">
                          <table className="table table-bordered table-striped">
                            <thead>
                              <tr>
                                  <th>{this.state.updates.isFiltered ? 'Barangays' : 'Town/City'}</th>
                                <th>Cases</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.renderMunicipalities(this.state.updates.areas)}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 py-3 px-0">
                <div className="card">
                  <div className="card-header">
  <h4 className='my-auto font-weight-bold'>Latest Updates <span className='float-right text-danger'> {this.state.targetPlace}</span></h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6 text-center totalPum">
                        <p className="text-black-50">TOTAL PUM</p> <h4 className="text-center">{
                          this.state.updates.total_under_monitoring || 0}</h4>
                      </div>
                      <div className="col-sm-6 text-center totalPui">
                        <p className="text-black-50">TOTAL PUI</p> <h4 className="text-center">{
                          this.state.updates.total_admission || 0}</h4>
                      </div>
                      <br></br><br></br>
                      <div className="col-sm-6 text-center recoveryRate">
                        <p className="text-black-50">RECOVERY RATE</p> <h5 className="text-center">{
                          this.state.updates.recovery_rate || 0}%</h5>
                      </div>
                      <div className="col-sm-6 text-center deathRate">
                        <p className="text-black-50">DEATH RATE</p> <h5 className="text-center">{
                          this.state.updates.death_rate || 0}%</h5>
                      </div>
                      <div className="col-sm-12 text-center totalCases">
                        <p className="text-black-50">TOTAL CASES</p> <h4 className="text-center">{
                          this.state.updates.total_case || 0}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 py-3 px-0 mt-10">
                <p><small>Latest Updates as of</small><i style={{ float: "right" }}>{new Date(this.state.updates.lastUpdate).toDateString() + " " + new Date(this.state.updates.lastUpdate).toLocaleTimeString() || ''}</i></p>
                <small>For more informations and updates, visit
                  <a href="https://ncovtracker.doh.gov.ph/" target="_blank"> DOH Website</a>
                  <a href="https://rnrph.com" style={{ float: "right" }} target="_blank">Created by RNRPH</a>
                </small>
              </div>
              <a href="javascript:void(0)" class="float" onClick={() => {
                this.setState({filterModal: true})
              }}>
                <p className="my-float">Filter by Town</p>
                {/* <i class="fa fa-filter my-float"></i> */}
              </a>

              {Modal({
          show: this.state.filterModal,
          size: "md",
          title: "Select Town/City",
          backdrop: true,
          content: <div class="mt-2 mb-2">
            <div class="row">
              <div class="col-md-8 offset-md-2">
                <select className="form-control" onChange={(e) => {
                  this.setState({toFilter: e.currentTarget.value});
                }}>
                  <option value='all' selected disabled={this.state.targetPlace == '' ? true : false}>Entire Pangasinan</option>
                  {this.handleFilterOptions()}
                </select>
              </div>
            </div>
          </div>,
          footer: [
            {
              class: 'btn-sm', text: 'CANCEL', onClick: () => { this.setState({ filterModal: false }) }
            },{
              class: 'btn-sm', text: 'FILTER', onClick: () => { 
                //HANDLE FILTER API
                if(this.state.toFilter != ''){
                  window.location.href = "/?area=" + this.state.toFilter;
                }                
              }
            }]
        })}

            </MDBContainer>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { loading } = this.state;
    if (loading) {
      return <LoadingScreen />
    } else {
      return this.renderMainDiv()
    }
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(index);
