import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBContainer, MDBCol, MDBRow, MDBBtn } from 'mdbreact';
import Tree from "react-d3-tree";
import ERequest from '../admission/components/request';
  
export class TreeView extends Component {
   constructor(props) {
    super(props)
    this.state = {
      PUI: [],
      loading: true
    }
   }

componentWillMount = async () => {
  try{
    const PUIDATA = await ERequest('get', 'https://ncov-monitoring-api.herokuapp.com/api/tracking/', {}, {})
    //ADD INDEXES
    let childs = PUIDATA.data.data.map((child, index) => {
      return {
        index: index,
        active: false,
        name: child.name,
        attributes: child.attributes,
        nodeSvgShape: child.nodeSvgShape,
        children: child.children
      }
    });
    let puidata = [{
      name: 'PUI',
      active: true,
      attributes: {
          // Name: 'Persons\nUnder\nInvestigation',
      },
      nodeSvgShape: {
        shape: 'circle',
        shapeProps: { r: 10, fill: 'orange',},
      },
      children: childs
    }];
    this.setState({PUI: puidata});
  }catch(error){
    console.log(error)
  }
}
componentDidMount = async() =>{
  try{
    const dimensions = this.treeContainer.getBoundingClientRect();
    this.setState({
      translate: {
        x: dimensions.width / 8,
        y: dimensions.height / 3
      },
      loading: false
    });
  }catch(error){
    console.log(error)
  }
    
}

handleClick = async (nodeData, evt) => {
  let tracks = this.state.PUI;
  let parent = this.findParent(tracks, nodeData.name)
  if(!nodeData.active){
    this.setState({loading: true})
    const child = await ERequest('get', 'https://ncov-monitoring-api.herokuapp.com/api/tracking/'+nodeData.name, {}, {})
    let track = this.findTrack(tracks, 'name', nodeData.name)
    track.active = true;
    track.children = child.data.children;
    console.log(track, parent)
    this.setState({PUI: parent, loading: false});
  }
}

findParent(arr, str){
  const find = ({ name, children }) => name.includes(str) || children && children.some(find);
  return arr.filter(find);
}

findTrack(entireObj, keyToFind, valToFind){
  let foundObj;
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue && nestedValue[keyToFind] === valToFind) {
      foundObj = nestedValue;
    }
    return nestedValue;
  });
  return foundObj;
}

loader(){
  if(this.state.loading){
    return <div style={cover}>
        <center>
        <div style={center} className="spinner-grow fast ml-3 text-warning" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        </center>
      </div>
  }else{
    return null
  }
}

render() {
  return (
    <MDBContainer fluid className='card'>
      <h2 className="text-center pt-4 font-weight-bold">Person Tagging in Tree View</h2>
      <hr/>
      {this.loader()}
      <div id="treeWrapper" style={containerStyles} ref={tc => (this.treeContainer = tc)}>
        {this.state.PUI.length > 0 ? 
        <Tree 
          data={this.state.PUI}
          translate={this.state.translate}
          separation={{siblings: 1, nonSiblings: 1}}
          onClick={this.handleClick}
        /> : null}
      </div>
    </MDBContainer >
    );
}
}
const containerStyles = {
  width: '100%',
  height: '83vh',
}

const cover = {
  display: 'block',
  position: 'absolute',
  background: 'transparent',
  width: '97.5%',
  height: '97.5vh',
}

const center = {
  marginLeft: '50vw',
  marginTop: '47vh',
}

export default connect(
  null,
  null
)(TreeView);