import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Login from '../login/index';
import Monitoring from '../Monitoring/index'
import Landing from '../landing/index'
import LandingV2 from '../landingv2/index'
// import Dashboard from '../Dashboard/index'
import Dashboard from '../Dashboard/index'
import Tree from '../tree/index'
import Logs from '../logs/index'
import React, { Component } from 'react';

export class index extends Component {
  handleLogin = () => {
    if (window.localStorage.getItem('token') === null && window.location.pathname !== '/login') {
      window.location.replace('/login');
    }
  };

  render() {
    return (
      <Router>
        <Switch>
          {/* <Route exact path='/' component={Landing} /> */}
          <Route exact path='/' component={LandingV2} />
          <Route path='/admin/login' component={Login} />
          <Route path='/admin/logs' component={Logs} isPrivate />
          <Route path='/admin/dashboard' component={Dashboard} isPrivate />
          <Route path='/admin/monitoring' component={Monitoring} isPrivate />
          <Route path='/admin/tagging' component={Tree} isPrivate />
          <Route component={Login} />
        </Switch>
      </Router>
    );
  }
}

export default index;
