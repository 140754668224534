import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../assets/styling/SchoolDirectory.css'
import { MDBContainer, MDBCol, MDBRow, MDBBtn, MDBModal, MDBModalHeader, MDBNav, MDBNavItem, MDBNavLink, MDBTabContent, MDBTabPane, MDBModalBody, MDBModalFooter, MDBIcon, TablePagination } from 'mdbreact';
import { getSchoolDirectory, deleteSchoolDirectory, editSchoolDirectory, createSchoolDirectory } from '../../reducer/Reducer'
import LoadingScreen from '../../components/loadingScreen';
import SimpleCrypt from './components/simpleEncrypt';
import ERequest from './components/request';
import AdmitModal from './components/admitModalContent';
import Modal from './components/modal';
import CustomTable from './components/table';
import PLoader from './components/processLoader';
import PersonsFullInfo from './components/personInfoHandler';
import PUM from './handlers/pum';
import PUI from './handlers/pui';
import CONFIRMED from './handlers/confirmed';
import CLEARED from './handlers/cleared';
import RECOVERED from './handlers/recovered';
import DECEASED from './handlers/deceased';

export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      modal: false,

      processing: false,
      processed: false,
      pumModal: false,
      puiModal1: false,
      puiModal2: false,
      puiModal3: false,
      confirmMdal: false,
      clearedModal: false,
      recoveredModal: false,
      deadModal: false,

      toggleAdd: false,
      userInfo: [],
      loading: true,
      mainLoading: true,
      apiError: false,
      activeItem: "1",
      modal6: false,
      searchQuery: '',
      reQuery: false,

      tableData: [],
      puidata: {},
      puitags: [],

      toAdmit: {},
      admitStage: '',
      facility: '',
      remarks: '',

      itoAdmit: {},
      iadmitStage: '',
      ifacility: '',
      iremarks: '',

      ctoAdmit: {},
      cadmitStage: '',
      cfacility: '',
      cremarks: '',

      pcldata: {},
      pclhistory: {},

      dddata: {},
      ddhistory: {},

      page: 0,
      size: 100,
      search: '',

      url: 'https://ncov-monitoring-api.herokuapp.com/api/monitoring/persons/',
      admitUrl: 'https://ncov-monitoring-api.herokuapp.com/api/admission/',
      searchUrl: 'https://ncov-monitoring-api.herokuapp.com/api/search',
    };
  }

  componentDidMount() {
    this.getAllPersons()
  }

  // componentDidUpdate(prevProps){
  //   console.log(prevProps)
  // }

  UNSAFE_componentWillReceiveProps(nextProps) {

    setTimeout(() => {
      console.log("triggered")
      if (nextProps.data !== "") {
        if (nextProps.data !== this.state.tableData) {
          this.setState({ tableData: nextProps.data })
        }
      }
    }, 700)
  }

  getAllPersons = async () => {
    let lvl = localStorage.getItem("user-level");
    this.setState({ userlevel: lvl })
    if (lvl == "SA" || lvl == "LA") {
      this.fetchData("PUM-QR", "pumQR", false);
      this.setState({
        loading: false,
        mainLoading: false
      })
    } else {
      // alert("Permission denied. Your account is not authorized. Please login to an authorized account to continue")
      localStorage.removeItem('token')
      localStorage.removeItem('user-level')
      localStorage.removeItem('area-code')
      window.location.href = '/admin/login'
    }

  }

  toggleProcessing = () => {
    this.setState({ processing: !this.state.processing });
  }

  toggleTab = tab => e => {
    let opt = {
      "1": ["PUM-QR", "pumQR"],
      "2": ["PUI-AD", "puiAD"],
      "3": ["CONFIRMED", "confirmed"],
      "4": ["CL", "cleared"],
      "5": ["RC", "recovered"],
      "6": ["DEAD", "died"]
    }
    if (this.state.activeItem !== tab) {
      this.fetchData(opt[tab][0], opt[tab][1], tab);
      this.setState({
        activeItem: tab
      })
    }
  };

  fetchData = async (stage, tbldata, tab) => {
    let params = {
      stage: stage,
      page: this.state.page,
      size: this.state.size
    }
    let res = await ERequest('get', this.state.url, null, params, {});
    let dt = this.state.tableData;
    dt[tbldata] = await PersonsFullInfo(this.state, params, res) || [];
    console.log(dt);
    if (tab) {
      this.setState({
        activeItem: tab,
        tableData: dt,
      });
    } else {
      this.setState({
        tableData: dt,
      });
    }
  }

  handleTriggerModal = nr => () => {
    if (nr != 'admit') {
      let modalNumber = 'modal' + nr
      this.setState({
        [modalNumber]: !this.state[modalNumber]
      });
    } else {
      this.setState({
        pumModal: !this.state.pumModal
      });
    }
  }

  recieveState = (states) => {
    this.setState(states)
  }

  renderMain(tableData) {
    return (
      <MDBContainer className='container card p-0'>
        <MDBNav className="nav-tabs nav-justify">
          <MDBCol className="px-0">
            <MDBNavItem>
              <MDBNavLink style={{ height: '8vh' }} to="#" className={(this.state.activeItem === "1") ? 'active-nav' : ''} onClick={this.toggleTab("1")} role="tab" >
                PUM
              </MDBNavLink>
            </MDBNavItem>
          </MDBCol>
          <MDBCol className="px-0">
            <MDBNavItem>
              <MDBNavLink to="#" className={(this.state.activeItem === "2") ? 'active-nav' : ''} onClick={this.toggleTab("2")} role="tab" >
                PUI
              </MDBNavLink>
            </MDBNavItem>
          </MDBCol>
          <MDBCol className="px-0">
            <MDBNavItem>
              <MDBNavLink to="#" className={(this.state.activeItem === "3") ? 'active-nav' : ''} onClick={this.toggleTab("3")} role="tab" >
                CONFIRMED
              </MDBNavLink>
            </MDBNavItem>
          </MDBCol>
          <MDBCol className="px-0">
            <MDBNavItem>
              <MDBNavLink to="#" className={(this.state.activeItem === "4") ? 'active-nav' : ''} onClick={this.toggleTab("4")} role="tab" >
                CLEARED
              </MDBNavLink>
            </MDBNavItem>
          </MDBCol>
          <MDBCol className="px-0">
            <MDBNavItem>
              <MDBNavLink to="#" className={(this.state.activeItem === "5") ? 'active-nav' : ''} onClick={this.toggleTab("5")} role="tab" >
                RECOVERED
              </MDBNavLink>
            </MDBNavItem>
          </MDBCol>
          <MDBCol className="px-0">
            <MDBNavItem>
              <MDBNavLink to="#" className={(this.state.activeItem === "6") ? 'active-nav' : ''} onClick={this.toggleTab("6")} role="tab" >
                DECEASED
              </MDBNavLink>
            </MDBNavItem>
          </MDBCol>
        </MDBNav>
        <MDBTabContent activeItem={this.state.activeItem} >
          <MDBTabPane tabId="1" role="tabpanel">
            <MDBRow>
              <MDBBtn className='ml-auto mr-3' color="danger" onClick={this.handleTriggerModal(6)} style={{ display: 'inline-block' }}>
                <MDBIcon size='2x' icon="plus-circle" />
              </MDBBtn>
              <MDBModal isOpen={this.state.modal6} toggle={this.handleTriggerModal(6)} side position="top-right">
                <MDBModalHeader toggle={this.handleTriggerModal(6)}>Select Options</MDBModalHeader>
                <MDBModalBody>
                  <MDBContainer>
                    <MDBCol className='my-2'>
                      <MDBNavLink className='bg-default text-white rounded text-center' to='/add_pum'>Add PUM</MDBNavLink>
                    </MDBCol>
                    <MDBCol className='my-2'>
                      <MDBNavLink className='bg-primary text-white rounded text-center' to='/upload_csv'>Upload CSV</MDBNavLink>
                    </MDBCol>
                  </MDBContainer>
                </MDBModalBody>
              </MDBModal>
            </MDBRow>

            <PUM data={this.state} setState={this.recieveState} />

          </MDBTabPane>
          <MDBTabPane tabId="2" role="tabpanel">

            <PUI data={this.state} setState={this.recieveState} />

          </MDBTabPane>
          <MDBTabPane tabId="3" role="tabpanel">

            <CONFIRMED data={this.state} setState={this.recieveState} />

          </MDBTabPane>
          <MDBTabPane tabId="4" role="tabpanel">

            <CLEARED data={this.state} setState={this.recieveState} />

          </MDBTabPane>
          <MDBTabPane tabId="5" role="tabpanel">

            <RECOVERED data={this.state} setState={this.recieveState} />

          </MDBTabPane>
          <MDBTabPane tabId="6" role="tabpanel">

            <DECEASED data={this.state} setState={this.recieveState} />

          </MDBTabPane>
        </MDBTabContent>

        {PLoader({
          processing: this.state.processing,
          processed: this.state.processed,
          initText: 'Processing',
          finishText: 'Done',
        })}
      </MDBContainer >
    );
  }

  render() {
    const { mainLoading, tableData } = this.state;
    if (mainLoading) {
      return <LoadingScreen />
    } else {
      return this.renderMain(tableData)
    }
  }
}

const mapStateToProps = state => {
  return {
    getSchoolDirectoryState: state.getSchoolDirectoryState,
    deleteSchoolDirectoryState: state.deleteSchoolDirectoryState,
    editSchoolDirectoryState: state.editSchoolDirectoryState,
    createSchoolDirectoryState: state.createSchoolDirectoryState
  };
};

const mapDispatchToProps = {
  getSchoolDirectory,
  deleteSchoolDirectory,
  editSchoolDirectory,
  createSchoolDirectory
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(index);

