import React from 'react';
import { MDBCol, MDBModal, MDBModalHeader, MDBModalBody, MDBBtn, MDBModalFooter } from 'mdbreact'

export default function Modal(props) {

  let footer = props.footer.map((el, index) => {
    return <MDBBtn className={el.class} onClick={el.onClick}>{el.text}</MDBBtn>
  });

  return (<MDBCol>
    <MDBModal isOpen={props.show} size={props.size} centered={false} disableBackdrop={!props.backdrop}>
      <MDBModalHeader className="">{props.title}</MDBModalHeader>
      <MDBModalBody className="">
        {props.content}
      </MDBModalBody>
      <MDBModalFooter>
        {footer}
      </MDBModalFooter>
    </MDBModal>
  </MDBCol>);
}