export const towns = [
  {
    "location": "Agno",
    "code": 2408,
    "barangay_list": [
      "ALLABON",
      "ALOLENG",
      "BANGAN-ODA",
      "BARUAN",
      "BOBOY",
      "CAYUNGNAN",
      "DANGLEY",
      "GAYUSAN",
      "MACABOBONI",
      "MAGSAYSAY",
      "NAMATUCAN",
      "PATAR",
      "POBLACION EAST",
      "POBLACION WEST",
      "SAN JUAN",
      "TUPA",
      "VIGA"
    ]
  },
  {
    "location": "Aguilar",
    "code": 2415,
    "barangay_list": [
      "BAYAOAS",
      "BAYBAY",
      "BOCACLIW",
      "BOCBOC EAST",
      "BOCBOC WEST",
      "BUER",
      "CALSIB",
      "LAOAG",
      "MANLOCBOC",
      "NINOY",
      "PANACOL",
      "POBLACION",
      "POGOMBOA",
      "POGONSILI",
      "SAN JOSE",
      "TAMPAC"
    ]
  },
  {
    "location": "Alaminos City",
    "code": 2404,
    "barangay_list": [
      "ALOS",
      "AMANDIEGO",
      "AMANGBANGAN",
      "BALANGOBONG",
      "BALAYANG",
      "BALEYADAAN",
      "BISOCOL",
      "BOLANEY",
      "BUED",
      "CABATUAN",
      "CAYUCAY",
      "DULACAC",
      "INERANGAN",
      "LANDOC",
      "LINMANSANGAN",
      "LUCAP",
      "MAAWI",
      "MACATIW",
      "MAGSAYSAY",
      "MONA",
      "PALAMIS",
      "PANDAN",
      "PANGAPISAN",
      "POBLACION",
      "POCALPOCAL",
      "POGO",
      "POLO",
      "QUIBUAR",
      "SABANGAN",
      "SAN ANTONIO (R. MAGSAYSAY)",
      "SAN JOSE",
      "SAN ROQUE",
      "SAN VICENTE",
      "SANTA MARIA",
      "TANAYTAY",
      "TANGCARANG",
      "TAWINTAWIN",
      "TELBANG",
      "VICTORIA"
    ]
  },
  {
    "location": "Alcala",
    "code": 2425,
    "barangay_list": [
      "ANULID",
      "ATAINAN",
      "BERSAMIN",
      "CANARVACANAN",
      "CARANGLAAN",
      "CURARENG",
      "GUALSIC",
      "KASIKIS",
      "LAOAC",
      "MACAYO",
      "PINDANGAN CENTRO",
      "PINDANGAN EAST",
      "PINDANGAN WEST",
      "POBLACION EAST",
      "POBLACION WEST",
      "SAN JUAN",
      "SAN NICOLAS",
      "SAN PEDRO APARTADO",
      "SAN PEDRO ILI",
      "SAN VICENTE",
      "VACANTE"
    ]
  },
  {
    "location": "Anda",
    "code": 2405,
    "barangay_list": [
      "AWAG",
      "AWILE",
      "BATIARAO",
      "CABUNGAN",
      "CAROT",
      "DOLAOAN",
      "IMBO",
      "MACALEENG",
      "MACANDOCANDONG",
      "MAL-ONG",
      "NAMAGBAGAN",
      "POBLACION",
      "ROXAS",
      "SABLIG",
      "SAN JOSE",
      "SIAPAR",
      "TONDOL",
      "TORITORI"
    ]
  },
  {
    "location": "Asingan",
    "code": 2439,
    "barangay_list": [
      "ARISTON ESTE",
      "ARISTON WESTE",
      "BANTOG",
      "BARO",
      "BOBONAN",
      "CABALITIAN",
      "CALEPAAN",
      "CAROSUCAN NORTE",
      "CAROSUCAN SUR",
      "COLDIT",
      "DOMANPOT",
      "DUPAC",
      "MACALONG",
      "PALARIS",
      "POBLACION EAST",
      "POBLACION WEST",
      "SAN VICENTE ESTE",
      "SAN VICENTE WESTE",
      "SANCHEZ",
      "SOBOL",
      "TOBOY"
    ]
  },
  {
    "location": "Balungao",
    "code": 2442,
    "barangay_list": [
      "ANGAYAN NORTE",
      "ANGAYAN SUR",
      "CAPULAAN",
      "ESMERALDA",
      "KITA-KITA",
      "MABINI",
      "MAUBAN",
      "POBLACION",
      "PUGARO",
      "RAJAL",
      "SAN ANDRES",
      "SAN AURELIO 1ST",
      "SAN AURELIO 2ND",
      "SAN AURELIO 3RD",
      "SAN JOAQUIN",
      "SAN JULIAN",
      "SAN LEON",
      "SAN MARCELINO",
      "SAN MIGUEL",
      "SAN RAYMUNDO"
    ]
  },
  {
    "location": "Bani",
    "code": 2407,
    "barangay_list": [
      "AMBABAAY",
      "APORAO",
      "ARWAS",
      "BALLAG",
      "BANOG NORTE",
      "BANOG SUR",
      "CALABENG",
      "CENTRO TOMA",
      "COLAYO",
      "DACAP NORTE",
      "DACAP SUR",
      "GARRITA",
      "LUAC",
      "MACABIT",
      "MASIDEM",
      "POBLACION",
      "QUINAOAYANAN",
      "RANAO",
      "RANOM ILOCO",
      "SAN JOSE",
      "SAN MIGUEL",
      "SAN SIMON",
      "SAN VICENTE",
      "TIEP",
      "TIPOR",
      "TUGUI GRANDE",
      "TUGUI NORTE"
    ]
  },
  {
    "location": "Basista",
    "code": 2422,
    "barangay_list": [
      "ANAMBONGAN",
      "BAYOYONG",
      "CABELDATAN",
      "DUMPAY",
      "MALIMPEC EAST",
      "MAPOLOPOLO",
      "NALNERAN",
      "NAVATAT",
      "OBONG",
      "OSMENA SR.",
      "PALMA",
      "PATACBO",
      "POBLACION"
    ]
  },
  {
    "location": "Bautista",
    "code": 2424,
    "barangay_list": [
      "ARTACHO",
      "BALUYOT",
      "CABUAAN",
      "CACANDONGAN",
      "DIAZ",
      "KETEGAN",
      "NANDACAN",
      "NIBALIW NORTE",
      "NIBALIW SUR",
      "PALISOC",
      "POBLACION EAST",
      "POBLACION WEST",
      "POGO",
      "POPONTO",
      "PRIMICIAS",
      "SINABAAN",
      "VACANTE",
      "VILLANUEVA"
    ]
  },
  {
    "location": "Bayambang",
    "code": 2423,
    "barangay_list": [
      "ALINGGAN",
      "AMAMPEREZ",
      "AMANCOSILING NORTE",
      "AMANCOSILING SUR",
      "AMBAYAT I",
      "AMBAYAT II",
      "APALEN",
      "ASIN",
      "ATAYNAN",
      "BACNONO",
      "BALAYBUAYA",
      "BANABAN",
      "BANI",
      "BATANGCAWA",
      "BELENG",
      "BICAL NORTE",
      "BICAL SUR",
      "BONGATO EAST",
      "BONGATO WEST",
      "BUAYAEN",
      "BUENLAG 1ST",
      "BUENLAG 2ND",
      "CADRE SITE",
      "CARUNGAY",
      "CATURAY",
      "DARAWEY (TANGAL)",
      "DUERA",
      "DUSOC",
      "HERMOZA",
      "IDONG",
      "INANLORENZANA",
      "INIRANGAN",
      "ITON",
      "LANGIRAN",
      "LIGUE",
      "M. H. DEL PILAR",
      "MACAYOCAYO",
      "MAGSAYSAY",
      "MAIGPA",
      "MALIMPEC",
      "MALIOER",
      "MANAGOS",
      "MANAMBONG NORTE",
      "MANAMBONG PARTE",
      "MANAMBONG SUR",
      "MANGAYAO",
      "NALSIAN NORTE",
      "NALSIAN SUR",
      "PANGDEL",
      "PANTOL",
      "PARAGOS",
      "POBLACION SUR",
      "PUGO",
      "REYNADO",
      "SAN GABRIEL 1ST",
      "SAN GABRIEL 2ND",
      "SAN VICENTE",
      "SANGCAGULIS",
      "SANLIBO",
      "SAPANG",
      "TAMARO",
      "TAMBAC",
      "TAMPOG",
      "TANOLONG",
      "TATARAO",
      "TELBANG",
      "TOCOC EAST",
      "TOCOC WEST",
      "WARDING",
      "WAWA",
      "ZONE I (POB.)",
      "ZONE II (POB.)",
      "ZONE III (POB.)",
      "ZONE IV (POB.)",
      "ZONE V (POB.)",
      "ZONE VI (POB.)",
      "ZONE VII (POB.)"
    ]
  },
  {
    "location": "Binalonan",
    "code": 2436,
    "barangay_list": [
      "BALANGOBONG",
      "BUED",
      "BUGAYONG",
      "CAMANGAAN",
      "CANARVACANAN",
      "CAPAS",
      "CILI",
      "DUMAYAT",
      "LINMANSANGAN",
      "MANGCASUY",
      "MORENO",
      "PASILENG NORTE",
      "PASILENG SUR",
      "POBLACION",
      "SAN FELIPE CENTRAL",
      "SAN FELIPE SUR",
      "SAN PABLO",
      "SANTA CATALINA",
      "SANTA MARIA NORTE",
      "SANTIAGO",
      "SANTO NI\u00d1O",
      "SUMABNIT",
      "TABUYOC",
      "VACANTE"
    ]
  },
  {
    "location": "Binmaley",
    "code": 2417,
    "barangay_list": [
      "AMANCORO",
      "BALAGAN",
      "BALOGO",
      "BASING",
      "BAYBAY LOPEZ",
      "BAYBAY POLONG",
      "BIEC",
      "BUENLAG",
      "CALIT",
      "CALOOCAN DUPO",
      "CALOOCAN NORTE",
      "CALOOCAN SUR",
      "CAMALEY",
      "CANAOALAN",
      "DULAG",
      "GAYAMAN",
      "LINOC",
      "LOMBOY",
      "MALINDONG",
      "MANAT",
      "NAGPALANGAN",
      "NAGUILAYAN",
      "PALLAS",
      "PAPAGUEYAN",
      "PARAYAO",
      "POBLACION",
      "POTOTAN",
      "SABANGAN",
      "SALAPINGAO",
      "SAN ISIDRO NORTE",
      "SAN ISIDRO SUR",
      "SANTA ROSA",
      "TOMBOR"
    ]
  },
  {
    "location": "Bolinao",
    "code": 2406,
    "barangay_list": [
      "ARNEDO",
      "BALINGASAY",
      "BINABALIAN",
      "CABUYAO",
      "CATUDAY",
      "CATUNGI",
      "CONCORDIA (POB.)",
      "CULANG",
      "DEWEY",
      "ESTANZA",
      "GERMINAL (POB.)",
      "GOYODEN",
      "ILOGMALINO",
      "LAMBES (LAMES)",
      "LIWA-LIWA",
      "LUCERO",
      "LUCIENTE 1.0",
      "LUCIENTE 2.0",
      "LUNA",
      "PATAR",
      "PILAR",
      "SALUD",
      "SAMANG NORTE",
      "SAMANG SUR",
      "SAMPALOC",
      "SAN ROQUE",
      "TARA",
      "TUPA",
      "VICTORY",
      "ZARAGOZA"
    ]
  },
  {
    "location": "Bugallon",
    "code": 2416,
    "barangay_list": [
      "ANGARIAN",
      "ASINAN",
      "BACABAC",
      "BANAGA",
      "BOLAOEN",
      "BUENLAG",
      "CABAYAOASAN",
      "CAYANGA",
      "GUESET",
      "HACIENDA",
      "LAGUIT CENTRO",
      "LAGUIT PADILLA",
      "MAGTAKING",
      "PANGASCASAN",
      "PANTAL",
      "POBLACION",
      "POLONG",
      "PORTIC",
      "SALASA",
      "SALOMAGUE NORTE",
      "SALOMAGUE SUR",
      "SAMAT",
      "SAN FRANCISCO",
      "UMANDAY"
    ]
  },
  {
    "location": "Burgos",
    "code": 2410,
    "barangay_list": [
      "ANAPAO (BUR ANAPAC)",
      "CACAYASEN",
      "CONCORDIA",
      "DON MATIAS",
      "ILIO-ILIO (ILIW-ILIW)",
      "PAPALLASEN",
      "POBLACION",
      "POGORUAC",
      "SAN MIGUEL",
      "SAN PASCUAL",
      "SAN VICENTE",
      "SAPA GRANDE",
      "SAPA PEQUE\u00d1A",
      "TAMBACAN"
    ]
  },
  {
    "location": "Calasiao",
    "code": 2418,
    "barangay_list": [
      "AMBONAO",
      "AMBUETEL",
      "BANAOANG",
      "BUED",
      "BUENLAG",
      "CABILOCAAN",
      "DINALAOAN",
      "DOYONG",
      "GABON",
      "LASIP",
      "LONGOS",
      "LUMBANG",
      "MACABITO",
      "MALABAGO",
      "MANCUP",
      "NAGSAING",
      "NALSIAN",
      "POBLACION EAST",
      "POBLACION WEST",
      "QUESBAN",
      "SAN MIGUEL",
      "SAN VICENTE",
      "SONGKOY",
      "TALIBAEW"
    ]
  },
  {
    "location": "Dagupan City",
    "code": 2400,
    "barangay_list": [
      "BACAYAO NORTE",
      "BACAYAO SUR",
      "BARANGAY I (T. BUGALLON)",
      "BARANGAY II (NUEVA)",
      "BARANGAY IV (ZAMORA)",
      "BOLOSAN",
      "BONUAN BINLOC",
      "BONUAN BOQUIG",
      "BONUAN GUESET",
      "CALMAY",
      "CARAEL",
      "CARANGLAAN",
      "HERRERO",
      "LASIP CHICO",
      "LASIP GRANDE",
      "LOMBOY",
      "LUCAO",
      "MALUED",
      "MAMALINGLING",
      "MANGIN",
      "MAYOMBO",
      "PANTAL",
      "POBLACION OESTE",
      "POGO CHICO",
      "POGO GRANDE",
      "PUGARO SUIT",
      "SALAPINGAO",
      "SALISAY",
      "TAMBAC",
      "TAPUAC",
      "TEBENG"
    ]
  },
  {
    "location": "Dasol",
    "code": 2411,
    "barangay_list": [
      "ALILAO",
      "AMALBALAN",
      "BOBONOT",
      "EGUIA",
      "GAIS-GUIPE",
      "HERMOSA",
      "MACALANG",
      "MAGSAYSAY",
      "MALACAPAS",
      "MALIMPIN",
      "OSME\u00d1A",
      "PETAL",
      "POBLACION",
      "SAN VICENTE",
      "TAMBAC",
      "TAMBOBONG",
      "ULI",
      "VIGA"
    ]
  },
  {
    "location": "Infanta",
    "code": 2412,
    "barangay_list": [
      "BABUYAN",
      "BAMBAN",
      "BATANG",
      "BAYAMBANG",
      "CATO",
      "DOLIMAN",
      "FATIMA",
      "MAYA",
      "NANGALISAN",
      "NAYOM",
      "PITA",
      "POBLACION",
      "POTOL"
    ]
  },
  {
    "location": "Labrador",
    "code": 2402,
    "barangay_list": [
      "BOLO",
      "BONGALON",
      "DULIG",
      "LAOIS",
      "MAGSAYSAY",
      "POBLACION",
      "SAN GONZALO",
      "SAN JOSE",
      "TOBUAN",
      "UYONG"
    ]
  },
  {
    "location": "Laoac",
    "code": 2437,
    "barangay_list": [
      "ANIS",
      "BALLIGI",
      "BANUAR",
      "BOTIQUE",
      "CAARINGAYAN",
      "CABILAOAN WEST",
      "CABULALAAN",
      "CALAOAGAN",
      "CALMAY",
      "CASAMPAGAAN",
      "CASANESTEBANAN",
      "CASANTIAGOAN",
      "DOMINGO ALARCIO (CABILAOAN EAST)",
      "INMANDUYAN",
      "LEBUEG",
      "MARABOC",
      "NANBAGATAN",
      "PANAGA",
      "POBLACION (LAOAC)",
      "TALOGTOG",
      "TURKO",
      "YATYAT"
    ]
  },
  {
    "location": "Lingayen",
    "code": 2401,
    "barangay_list": [
      "ALIWEKWEK",
      "BAAY",
      "BALANGOBONG",
      "BALOCOC",
      "BANTAYAN",
      "BASING",
      "CAPANDANAN",
      "DOMALANDAN CENTER",
      "DOMALANDAN EAST",
      "DOMALANDAN WEST",
      "DORONGAN",
      "DULAG",
      "ESTANZA",
      "LASIP",
      "LIBSONG EAST",
      "LIBSONG WEST",
      "MALAWA",
      "MALIMPUEC",
      "MANIBOC",
      "MATALAVA",
      "NAGUELGUEL",
      "NAMOLAN",
      "PANGAPISAN NORTH",
      "PANGAPISAN SUR",
      "POBLACION",
      "QUIBAOL",
      "ROSARIO",
      "SABANGAN",
      "TALOGTOG",
      "TONTON",
      "TUMBAR",
      "WAWA"
    ]
  },
  {
    "location": "Mabini",
    "code": 2409,
    "barangay_list": [
      "BACNIT",
      "BARLO",
      "CAABIANGAAN",
      "CABANAETAN",
      "CABINUANGAN",
      "CALZADA",
      "CARANGLAAN",
      "DE GUZMAN",
      "LUNA",
      "MAGALONG",
      "NIBALIW",
      "PATAR",
      "POBLACION",
      "SAN PEDRO",
      "TAGUDIN",
      "VILLACORTA"
    ]
  },
  {
    "location": "Malasiqui",
    "code": 2421,
    "barangay_list": [
      "ABONAGAN",
      "AGDAO",
      "ALACAN",
      "ALIAGA",
      "AMACALAN",
      "ANOLID",
      "APAYA",
      "ASIN ESTE",
      "ASIN WESTE",
      "BACUNDAO ESTE",
      "BACUNDAO WESTE",
      "BAKITIW",
      "BALITE",
      "BANAWANG",
      "BARANG",
      "BAWER",
      "BINALAY",
      "BOBON",
      "BOLAOIT",
      "BONGAR",
      "BUTAO",
      "CABATLING",
      "CABUELDATAN",
      "CALBUEG",
      "CANAN NORTE",
      "CANAN SUR",
      "CAWAYAN BOGTONG",
      "DON PEDRO",
      "GATANG",
      "GOLIMAN",
      "GOMEZ",
      "GUILIG",
      "ICAN",
      "INGALAGALA",
      "LAREG-LAREG",
      "LASIP",
      "LEPA",
      "LOQUEB ESTE",
      "LOQUEB NORTE",
      "LOQUEB SUR",
      "LUNEC",
      "MABULITEC",
      "MALIMPEC",
      "MANGGAN-DAMPAY",
      "NALSIAN NORTE",
      "NALSIAN SUR",
      "NANCAPIAN",
      "NANSANGAAN",
      "OLEA",
      "PACUAN",
      "PALAPAR NORTE",
      "PALAPAR SUR",
      "PALONG",
      "PAMARANUM",
      "PASIMA",
      "PAYAR",
      "POBLACION",
      "POLONG NORTE",
      "POLONG SUR",
      "POTIOCAN",
      "SAN JULIAN",
      "TABO-SILI",
      "TALOSPATANG",
      "TALOY",
      "TALOYAN",
      "TAMBAC",
      "TOBOR",
      "TOLONGUAT",
      "TOMLING",
      "UMANDO",
      "VIADO",
      "WAIG",
      "WAREY"
    ]
  },
  {
    "location": "Manaoag",
    "code": 2430,
    "barangay_list": [
      "BABASIT",
      "BAGUINAY",
      "BARITAO",
      "BISAL",
      "BUCAO",
      "CABANBANAN",
      "CALAOCAN",
      "INAMOTAN",
      "LELEMAAN",
      "LICSI",
      "LIPIT NORTE",
      "LIPIT SUR",
      "MATOLONG",
      "MERMER",
      "NALSIAN",
      "ORAAN EAST",
      "ORAAN WEST",
      "PANTAL",
      "PAO",
      "PARIAN",
      "POBLACION",
      "PUGARO",
      "SAN RAMON",
      "SANTA INES",
      "SAPANG",
      "TEBUEL"
    ]
  },
  {
    "location": "Mangaldan",
    "code": 2432,
    "barangay_list": [
      "ALITAYA",
      "AMANSABINA",
      "ANOLID",
      "BANAOANG",
      "BANTAYAN",
      "BARI",
      "BATENG",
      "BUENLAG",
      "DAVID",
      "EMBARCADERO",
      "GUEGUESANGEN",
      "GUESANG",
      "GUIGUILONEN",
      "GUILIG",
      "INLAMBO",
      "LANAS",
      "LANDAS",
      "MAASIN",
      "MACAYUG",
      "MALABAGO",
      "NAVALUAN",
      "NIBALIW",
      "OSIEM",
      "PALUA",
      "POBLACION",
      "POGO",
      "SALAAN",
      "SALAY",
      "TALOGTOG",
      "TEBAG"
    ]
  },
  {
    "location": "Mangatarem",
    "code": 2413,
    "barangay_list": [
      "ANDANGIN",
      "ARELLANO STREET (POB.)",
      "BANTAY",
      "BANTOCALING",
      "BARACBAC",
      "BOGTONG BOLO",
      "BOGTONG BUNAO",
      "BOGTONG CENTRO",
      "BOGTONG NIOG",
      "BOGTONG SILAG",
      "BUAYA",
      "BUENLAG",
      "BUENO",
      "BUNAGAN",
      "BUNLALACAO",
      "BURGOS STREET (POB.)",
      "CABALUYAN 1ST",
      "CABALUYAN 2ND",
      "CABARABUAN",
      "CABARUAN",
      "CABAYAOASAN",
      "CABAYUGAN",
      "CACAOITEN",
      "CALUMBOYAN NORTE",
      "CALUMBOYAN SUR",
      "CALVO (POB.)",
      "CASILAGAN",
      "CATARATARAAN",
      "CATURAY NORTE",
      "CATURAY SUR",
      "CAVIERNESAN",
      "DORONGAN KETAKET",
      "DORONGAN LINMANSANGAN",
      "DORONGAN PUNTA",
      "DORONGAN SAWAT",
      "DORONGAN VALERIO",
      "GENERAL LUNA (POB.)",
      "HISTORIA",
      "LAWAK LANGKA",
      "LINMANSANGAN",
      "LOPEZ (POB.)",
      "MABINI (POB.)",
      "MACARANG",
      "MALABOBO",
      "MALIBONG",
      "MALUNEC",
      "MARAVILLA (POB.)",
      "MARAVILLA-ARELLANO EXT. (POB.)",
      "MUELANG",
      "NAGUILAYAN EAST",
      "NAGUILAYAN WEST",
      "NANCASALAN",
      "NIOG-CABISON-BULANEY",
      "OLEGARIO-CAOILE (POB.)",
      "OLO CACAMPOSAN",
      "OLO CAFABROSAN",
      "OLO CAGARLITAN",
      "OSME\u00d1A (POB.)",
      "PACALAT",
      "PAMPANO",
      "PARIAN",
      "PAUL",
      "PEANIA PEDANIA (BEDANIA)",
      "POGON-ANIAT",
      "POGON-LOMBOY (POB.)",
      "PONGLO-BALEG",
      "PONGLO-MUELAG",
      "QUETEGAN (POGON-BALEG)",
      "QUEZON (POB.)",
      "SALAVANTE",
      "SAPANG",
      "SONSON ONGKIT",
      "SUACO",
      "TAGAC",
      "TAKIPAN",
      "TALOGTOG",
      "TOCOC BARIKIR",
      "TORRE 1ST",
      "TORRE 2ND",
      "TORRES BUGALLON (POB.)",
      "UMANGAN",
      "ZAMORA (POB.)"
    ]
  },
  {
    "location": "Mapandan",
    "code": 2429,
    "barangay_list": [
      "AMANOAOAC",
      "APAYA",
      "ASERDA",
      "BALOLING",
      "CORAL",
      "GOLDEN",
      "JIMENEZ",
      "LAMBAYAN",
      "LUYAN (LUYAN SOUTH)",
      "NILOMBOT",
      "PIAS",
      "POBLACION",
      "PRIMICIAS",
      "SANTA MARIA (LUYAN NORTH)",
      "TORRES"
    ]
  },
  {
    "location": "Natividad",
    "code": 2446,
    "barangay_list": [
      "BARANGOBONG",
      "BATCHELOR EAST",
      "BATCHELOR WEST",
      "BURGOS (SAN NARCISO)",
      "CACANDUNGAN",
      "CALAPUGAN",
      "CANAREM",
      "LUNA",
      "POBLACION EAST",
      "POBLACION WEST",
      "RIZAL",
      "SALUD",
      "SAN EUGENIO",
      "SAN MACARIO NORTE",
      "SAN MACARIO SUR",
      "SAN MAXIMO",
      "SAN MIGUEL",
      "SILAG"
    ]
  },
  {
    "location": "Pozorrubio",
    "code": 2435,
    "barangay_list": [
      "ALIPANGPANG",
      "AMAGBAGAN",
      "BALACAG",
      "BANDING",
      "BANTUGAN",
      "BATAKIL",
      "BOBONAN",
      "BUNEG",
      "CABLONG",
      "CASANFERNANDOAN",
      "CASTA\u00d1O",
      "DILAN",
      "DON BENITO",
      "HAWAY",
      "IMBALBALATONG",
      "INOMAN",
      "LAOAC",
      "MAAMBAL",
      "MALASIN",
      "MALOKIAT",
      "MANAOL",
      "NAMA",
      "NANTANGALAN",
      "PALACPALAC",
      "PALGUYOD",
      "POBLACION I",
      "POBLACION II",
      "POBLACION III",
      "POBLACION IV",
      "ROSARIO",
      "SUGCONG",
      "TALOGTOG",
      "TULNAC",
      "VILLEGAS"
    ]
  },
  {
    "location": "Rosales",
    "code": 2441,
    "barangay_list": [
      "ACOP",
      "BAKITBAKIT",
      "BALINGCANAWAY",
      "CABALAOANGAN NORTE",
      "CABALAOANGAN SUR",
      "CALANUTAN (DON FELIX COLOMA)",
      "CAMANGAAN",
      "CAPITAN TOMAS",
      "CARMAY EAST",
      "CARMAY WEST",
      "CARMEN EAST",
      "CARMEN WEST",
      "CASANICOLASAN",
      "COLILING",
      "DON ANTONIO VILLAGE",
      "GUILING",
      "PALAKIPAK",
      "PANGAOAN",
      "RABAGO",
      "RIZAL",
      "SALVACION",
      "SAN ANGEL",
      "SAN ANTONIO",
      "SAN BARTOLOME",
      "SAN ISIDRO",
      "SAN LUIS",
      "SAN PEDRO EAST",
      "SAN PEDRO WEST",
      "SAN VICENTE",
      "STATION DISTRICT",
      "TOMANA EAST",
      "TOMANA WEST",
      "ZONE I (POB.)",
      "ZONE II (POB.)",
      "ZONE III (POB.)",
      "ZONE IV (POB.)",
      "ZONE V (POB.)"
    ]
  },
  {
    "location": "San Carlos City",
    "code": 2420,
    "barangay_list": [
      "ABANON",
      "AGDAO",
      "ANANDO",
      "ANO",
      "ANTIPANGOL",
      "APONIT",
      "BACNAR",
      "BALAYA",
      "BALAYONG",
      "BALDOG",
      "BALITE SUR",
      "BALOCOC",
      "BANI",
      "BEGA",
      "BOCBOC",
      "BOGAOAN",
      "BOLINGIT",
      "BOLOSAN",
      "BONIFACIO (POB.)",
      "BUENGLAT",
      "BUGALLON-POSADAS STREET (POB.)",
      "BURGOS PADLAN (POB.)",
      "CACARITAN",
      "CAINGAL",
      "CALOBAOAN",
      "CALOMBOYAN",
      "CAOAYAN-KILING",
      "CAPATAAN",
      "COBOL",
      "COLILING",
      "CRUZ",
      "DOYONG",
      "GAMATA",
      "GUELEW",
      "ILANG",
      "INERANGAN",
      "ISLA",
      "LIBAS",
      "LILIMASAN",
      "LONGOS",
      "LUCBAN (POB.)",
      "M. SORIANO",
      "MABALBALINO",
      "MABINI (POB.)",
      "MAGTAKING",
      "MALACA\u00d1ANG",
      "MALIWARA",
      "MAMARLAO",
      "MANZON",
      "MATAGDEM",
      "MESTIZO NORTE",
      "NAGUILAYAN",
      "NILENTAP",
      "PADILLA-GOMEZ",
      "PAGAL",
      "PAITAN-PANOYPOY",
      "PALAMING",
      "PALARIS (POB.)",
      "PALOSPOS",
      "PANGALANGAN",
      "PANGOLOAN",
      "PANGPANG",
      "PARAYAO",
      "PAYAPA",
      "PAYAR",
      "PEREZ BOULEVARD (POB.)",
      "PNR STATION SITE",
      "POLO",
      "QUEZON BOULEVARD (POB.)",
      "QUINTONG",
      "RIZAL (POB.)",
      "ROXAS BOULEVARD (POB.)",
      "SALINAP",
      "SAN JUAN",
      "SAN PEDRO-TALOY",
      "SAPINIT",
      "SUPO",
      "TALANG",
      "TAMAYO",
      "TANDANG SORA",
      "TANDOC",
      "TARECE",
      "TARECTEC",
      "TAYAMBANI",
      "TEBAG",
      "TURAC"
    ]
  },
  {
    "location": "San Fabian",
    "code": 2433,
    "barangay_list": [
      "ALACAN",
      "AMBALANGAN-DALIN",
      "ANGIO",
      "ANONANG",
      "ARAMAL",
      "BIGBIGA",
      "BINDAY",
      "BOLAOEN",
      "BOLASI",
      "CABARUAN",
      "CAYANGA",
      "COLISAO",
      "GOMOT",
      "INMALOG",
      "INMALOG NORTE",
      "LEKEP-BUTAO",
      "LIPIT-TOMEENG",
      "LONGOS",
      "LONGOS PROPER",
      "LONGOS-AMANGONAN-PARAC-PARAC FABRICA",
      "MABILAO",
      "NIBALIW CENTRAL",
      "NIBALIW EAST",
      "NIBALIW MAGLIBA",
      "NIBALIW NARVARTE (NIBALIW WEST COMPOUND)",
      "NIBALIW VIDAL (NIBALIW WEST PROPER)",
      "PALAPAD",
      "POBLACION",
      "RABON",
      "SAGUD-BAHLEY",
      "SOBOL",
      "TEMPRA-GUILIG",
      "TIBLONG",
      "TOCOK"
    ]
  },
  {
    "location": "San Jacinto",
    "code": 2431,
    "barangay_list": [
      "AWAI",
      "BAGONG PAG-ASA (POBLACION EAST)",
      "BOLO",
      "CAPAOAY (POB.)",
      "CASIBONG",
      "GUIBEL",
      "IMELDA (DECRITO)",
      "LABNEY",
      "LOBONG",
      "MACAYUG",
      "MAGSAYSAY (CAPAY)",
      "SAN GUILLERMO (POBLACION WEST)",
      "SAN JOSE",
      "SAN JUAN",
      "SAN ROQUE",
      "SAN VICENTE",
      "SANTA CRUZ",
      "SANTA MARIA",
      "SANTO TOMAS"
    ]
  },
  {
    "location": "San Manuel",
    "code": 2438,
    "barangay_list": [
      "CABACARAAN",
      "CABARITAN",
      "FLORES",
      "GUISET NORTE (POB.)",
      "GUISET SUR (POB.)",
      "LAPALO",
      "NAGSAAG",
      "NARRA",
      "SAN ANTONIO-ARZADON",
      "SAN BONIFACIO",
      "SAN JUAN",
      "SAN ROQUE",
      "SAN VICENTE",
      "SANTO DOMINGO"
    ]
  },
  {
    "location": "San Nicolas",
    "code": 2447,
    "barangay_list": [
      "BENSICAN",
      "CABITNONGAN",
      "CABOLOAN",
      "CACABUGAOAN",
      "CALANUTIAN",
      "CALAOCAN",
      "CAMANGGAAN",
      "CAMINDOROAN",
      "CASARATAN",
      "DALUMPINAS",
      "FIANZA",
      "LUNGAO",
      "MALICO",
      "MALILION",
      "NAGKAYSA",
      "NINING",
      "POBLACION EAST",
      "POBLACION WEST",
      "SALINGCOB",
      "SALPAD",
      "SAN FELIPE EAST",
      "SAN FELIPE WEST",
      "SAN ISIDRO (STA. CRUZAN)",
      "SAN JOSE",
      "SAN RAFAEL CENTRO",
      "SAN RAFAEL EAST",
      "SAN RAFAEL WEST",
      "SAN ROQUE",
      "SANTA MARIA EAST",
      "SANTA MARIA WEST",
      "SANTO TOMAS",
      "SIBLOT",
      "SOBOL"
    ]
  },
  {
    "location": "San Quintin",
    "code": 2444,
    "barangay_list": [
      "ALAC",
      "BALIGAYAN",
      "BANTOG",
      "BOLINTAGUEN",
      "CABALAOANGAN",
      "CABANGARAN",
      "CALOMBOYAN",
      "CARAYACAN",
      "CASANTAMARIAN",
      "GONZALO",
      "LABUAN",
      "LAGASIT",
      "LUMAYAO",
      "MABINI",
      "MANTACDANG",
      "NANGAPUGAN",
      "POBLACION ZONE I",
      "POBLACION ZONE II",
      "POBLACION ZONE III",
      "SAN PEDRO",
      "UNGIB"
    ]
  },
  {
    "location": "Santa Barbara",
    "code": 2419,
    "barangay_list": [
      "ALIBAGO",
      "BALINGUEO",
      "BANAOANG",
      "BANZAL",
      "BOTAO",
      "CABLONG",
      "CARUSOCAN",
      "DALONGUE",
      "ERFE",
      "GUEGUESANGEN",
      "LEET",
      "MALANAY",
      "MANINGDING",
      "MARONONG",
      "MATICMATIC",
      "MINIEN EAST",
      "MINIEN WEST",
      "NILOMBOT",
      "PATAYAC",
      "PAYAS",
      "POBLACION NORTE",
      "POBLACION SUR",
      "PRIMICIAS (VENTINILLA WEST)",
      "SAPANG",
      "SONQUIL",
      "TEBAG EAST",
      "TEBAG WEST",
      "TULIAO",
      "VENTINILLA (VENTINILLA EAST)"
    ]
  },
  {
    "location": "Santa Maria",
    "code": 2440,
    "barangay_list": [
      "BAL-LOY",
      "BANTOG",
      "CABOLUAN",
      "CAL-LITANG",
      "CAPANDANAN",
      "CAUPLASAN",
      "DALAYAP",
      "LIBSONG",
      "NAMAGBAGAN",
      "PAITAN",
      "PATAQUID",
      "PILAR",
      "POBLACION EAST",
      "POBLACION WEST",
      "PUGOT",
      "SAMON",
      "SAN ALEJANDRO",
      "SAN MARIANO",
      "SAN PABLO",
      "SAN PATRICIO",
      "SAN VICENTE",
      "SANTA CRUZ",
      "SANTA ROSA"
    ]
  },
  {
    "location": "Santo Tomas",
    "code": 2426,
    "barangay_list": [
      "LA LUNA",
      "POBLACION EAST",
      "POBLACION WEST",
      "SALVACION",
      "SAN AGUSTIN",
      "SAN ANTONIO",
      "SAN JOSE",
      "SAN MARCOS",
      "SANTO DOMINGO",
      "SANTO NI\u00d1O"
    ]
  },
  {
    "location": "Sison",
    "code": 2434,
    "barangay_list": [
      "AGAT",
      "ALIBENG",
      "AMAGBAGAN",
      "ARTACHO",
      "ASAN NORTE",
      "ASAN SUR",
      "BANTAY INSIK",
      "BILA",
      "BINMECKEG",
      "BULAOEN EAST",
      "BULAOEN WEST",
      "CABARITAN",
      "CALUNETAN",
      "CAMANGAAN",
      "CAURINGAN",
      "DUNGON",
      "ESPERANZA",
      "INMALOG",
      "KILLO",
      "LABAYUG",
      "PALDIT",
      "PINDANGAN",
      "PINMILAPIL",
      "POBLACION CENTRAL",
      "POBLACION NORTE",
      "POBLACION SUR",
      "SAGUNTO",
      "TARA-TARA"
    ]
  },
  {
    "location": "Sual",
    "code": 2403,
    "barangay_list": [
      "BAQUIOEN",
      "BAYBAY NORTE",
      "BAYBAY SUR",
      "BOLAOEN",
      "CABALITIAN",
      "CALUMBUYAN",
      "CAMAGSINGALAN",
      "CAOAYAN",
      "CAPANTOLAN",
      "MACAYCAYAWAN",
      "PAITAN EAST",
      "PAITAN WEST",
      "PANGASCASAN",
      "POBLACION",
      "SANTO DOMINGO",
      "SESELANGEN",
      "SIOASIO EAST",
      "SIOASIO WEST",
      "VICTORIA"
    ]
  },
  {
    "location": "Tayug",
    "code": 2445,
    "barangay_list": [
      "AGNO",
      "AMISTAD",
      "BARANGAY A (POB.)",
      "BARANGAY B (POB.)",
      "BARANGAY C (POB.)",
      "BARANGAY D (POB.)",
      "BARANGOBONG",
      "C. LICHAUCO",
      "CARRIEDO",
      "EVANGELISTA",
      "GUZON",
      "LAWAK",
      "LEGASPI",
      "LIBERTAD",
      "MAGALLANES",
      "PANGANIBAN",
      "SALENG",
      "SANTO DOMINGO",
      "TOKETEC",
      "TRENCHERA",
      "ZAMORA"
    ]
  },
  {
    "location": "Umingan",
    "code": 2443,
    "barangay_list": [
      "ABOT MOLINA",
      "ALO-O",
      "AMARONAN",
      "ANNAM",
      "BANTUG",
      "BARACBAC",
      "BARAT",
      "BUENAVISTA",
      "CABALITIAN",
      "CABANGARAN",
      "CABARUAN",
      "CABATUAN",
      "CADIZ",
      "CALITLITAN",
      "CAPAS",
      "CARAYUNGAN SUR",
      "CAROSALESAN",
      "CASILAN",
      "CAURDANETAAN",
      "CONCEPCION",
      "DECRETO",
      "DEL ROSARIO",
      "DIAZ",
      "DIKET",
      "DON JUSTO ABALOS (CAROAN)",
      "DON MONTANO",
      "ESPERANZA",
      "EVANGELISTA",
      "FLORES",
      "FULGOSINO",
      "GONZALES",
      "LA PAZ",
      "LABUAN",
      "LAUREN",
      "LUBONG",
      "LUNA ESTE",
      "LUNA WESTE",
      "MANTACDANG",
      "MASEIL-SEIL",
      "NAMPALCAN",
      "NANCALABASAAN",
      "PANGANGAAN",
      "PAPALLASEN",
      "PEMIENTA",
      "POBLACION EAST",
      "POBLACION WEST",
      "PRADO",
      "RESURRECCION",
      "RICOS",
      "SAN ANDRES",
      "SAN JUAN",
      "SAN LEON",
      "SAN PABLO",
      "SAN VICENTE",
      "SANTA MARIA",
      "SANTA ROSA",
      "SINABAAN",
      "TANGGAL SAWANG"
    ]
  },
  {
    "location": "Urbiztondo",
    "code": 2414,
    "barangay_list": [
      "ANGATEL",
      "BALANGAY",
      "BATANGCAOA",
      "BAUG",
      "BAYAOAS",
      "BITUAG",
      "CAMAMBUGAN",
      "DALANGIRING",
      "DUPLAC",
      "GALARIN",
      "GUETEB",
      "MALACA",
      "MALAYO",
      "MALIBONG",
      "PASIBI EAST",
      "PASIBI WEST",
      "PISUAC",
      "POBLACION",
      "REAL",
      "SALAVANTE",
      "SAWAT"
    ]
  },
  {
    "location": "Urdaneta",
    "code": 2428,
    "barangay_list": [
      "ANONAS",
      "BACTAD EAST",
      "BAYAOAS",
      "BOLAOEN",
      "CABARUAN",
      "CABULOAN",
      "CAMANANG",
      "CAMANTILES",
      "CASANTAAN",
      "CATABLAN",
      "CAYAMBANAN",
      "CONSOLACION",
      "DILAN PAURIDO",
      "DR. PEDRO T. ORATA (BACTAD PROPER)",
      "LABIT PROPER",
      "LABIT WEST",
      "MABANOGBOG",
      "MACALONG",
      "NANCALOBASAAN",
      "NANCAMALIRAN EAST",
      "NANCAMALIRAN WEST",
      "NANCAYASAN",
      "OLTAMA",
      "PALINA EAST",
      "PALINA WEST",
      "PINMALUDPOD",
      "POBLACION",
      "SAN JOSE",
      "SAN VICENTE",
      "SANTA LUCIA",
      "SANTO DOMINGO",
      "SUGCONG",
      "TIPUSO",
      "TULONG"
    ]
  },
  {
    "location": "Villasis",
    "code": 2427,
    "barangay_list": [
      "AMAMPEREZ",
      "BACAG",
      "BARANGOBONG",
      "BARRACA",
      "CAPULAAN",
      "CARAMUTAN",
      "LA PAZ",
      "LABIT",
      "LIPAY",
      "LOMBOY",
      "PIAZ (PLAZA)",
      "PUELAY",
      "SAN BLAS",
      "SAN NICOLAS",
      "TOMBOD",
      "UNZAD",
      "ZONE I (POB.)",
      "ZONE II (POB.)",
      "ZONE III (POB.)",
      "ZONE IV (POB.)",
      "ZONE V (POB.)"
    ]
  }
]