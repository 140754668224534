import React from 'react';
import ERequest from './request';

export default function PersonsFullInfo(state, params, data, auto = true) {

  const parse = async (params, data) => {
    let res = data.data.data;

    //QUARANTINE DATE
    for (var x = 0; x < res.length; x++) {
      res[x].days = res[x].quarantine_left + (res[x].quarantine_left > 1 ? ' days' : ' day') + " left"
    }

    //FACILITY
    for (var x = 0; x < res.length; x++) {
      res[x].facility = res[x].facility == '' ? 'Unspecified' : res[x].facility
    }

    //TAGS
    for (var x = 0; x < res.length; x++) {
      res[x].totalTags = res[x].tags
    }

    //SYMPTOMS
    for (var x = 0; x < res.length; x++) {
      let sy = symptoms(res[x].symptoms);
      let s = sy.length > 0 ? sy.toString() + '' : 'No symptoms'
      res[x].symptomsDOM = <a title="Show Symptoms" href="javascript:void(0)" onClick={sy.length > 0 ? () => alert(sy.toString()) : null}>{s}</a>;
    }

    //NAME WITH OTHER INFO
    for (var x = 0; x < res.length; x++) {
      let gender = res[x].gender == "Male" ? "M" : "F";
      res[x].nameInfo = res[x].name + " " + gender + " " + res[x].age + " " + res[x].nationality.substring(0, 3);
    }

    //DATES
    for (var x = 0; x < res.length; x++) {
      res[x].infodate = new Date(res[x].createdAt).toLocaleString();
      res[x].lastupdate = new Date(res[x].updatedAt).toLocaleString();
    }

    return res;
  }

  // function calculate(person) {
  //   for (var x = 0; x < person.data.length; x++) {
  //     let d = person.data[x].createdAt;
  //     let start = new Date(d);
  //     let today = new Date(Date.now());
  //     let past = parseInt(today.getDate()) - parseInt(start.getDate());
  //     if(past > 0){
  //       person.data[x].days = 14 - past + " days left";
  //     }else if(past <= 0){
  //       person.data[x].days = "Completed";
  //     }
  //   }
  //   return person;
  // }

  function symptoms(str) {
    let res = [];
    if (str.includes("fever")) res.push("fever")
    if (str.includes("cough")) res.push("cough")
    if (str.includes("cold")) res.push("cold")
    if (str.includes("sore throat")) res.push("sore throat")
    if (str.includes("diarrhea")) res.push("diarrhea")
    if (str.includes("difficulty in breathing")) res.push("difficulty in breathing")
    return res;
  }

  return parse(params, data);

}